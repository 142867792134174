<template>
     <div class="w-full h-full bg-stone-100 pt-5">
        <div class="px-5">
            <div class="mb-2">
                <a @click="this.$router.go(-1)" class="focus:outline-none hover:underline text-gray-500 text-sm cursor-pointer"><i class="mdi mdi-arrow-left text-gray-400"></i>Back</a>
            </div>
            <div class="mb-2">
                <h1 v-if="!loading" class="text-3xl md:text-5xl font-bold text-gray-600">{{ upperCaseFirst(this.$route.name) }}.</h1>

                <div v-if="loading" class="mt-4 mb-1">
                    <Skeleton width="420px" height="40px"></Skeleton>
                </div>
            </div>
            <div class="mb-5 text-gray-400">
                <div v-if="!loading">
                    <router-link :to="{name: 'dashboard'}" class="focus:outline-none hover:underline text-gray-500">Home</router-link> / <router-link :to="{name: 'distribution order'}" class="focus:outline-none hover:underline text-gray-500">Distribution Order</router-link>
                </div>

                <div v-if="loading" class="mt-4 mb-1">
                    <Skeleton width="130px" height="15px"></Skeleton>
                </div>
            </div>
        </div>
        
        <div class="relative w-full bg-white border-t border-gray-200 px-5 py-10 text-gray-800">
            <button v-if="!loading" @click="this.$router.push({name: 'create distribution order'})" class="text-white py-2 px-4 uppercase rounded bg-teal-600 hover:bg-teal-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5">
                Create DO
            </button>

            <div v-if="loading" class="relative z-10">
                <Skeleton width="112px" height="40px"></Skeleton>
            </div>
            
            <InputGroup v-if="!loading" class="absolute right-10 top-10" style="width: 500px">
                <Button style="background: rgb(41 37 36);border: none" label="Search" />
                <InputText v-model="searchValue" @input="searching" class="focus:outline-0 active:outline-0 active:outline-stone-900 focus:outline-stone-900" placeholder="Keyword" />
            </InputGroup>

            <div v-if="loading" class="absolute right-[40px] top-10">
                <Skeleton width="500px" height="42px"></Skeleton>
            </div>

            <div v-if="loading" class="relative mt-11">
                <Skeleton width="100%" height="70px"></Skeleton>
            </div>

            <div v-if="loading" class="relative mt-5">
                <Skeleton width="100%" height="70px"></Skeleton>
            </div>
          
            <div v-if="!loading" class="w-full mt-8">
                <DataTable class="bg-transparent" :value="distorders" removableSort sortMode="multiple" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50, 100]" tableStyle="min-width: 100%">
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 hover:bg-gray-200 dark:bg-stone-900 dark:hover:bg-stone-600 focus:bg-sky-200 dark:focus:bg-sky-200" field="cust_name" header="Customer" sortable style="width: 25%">
                        <template #body="data">
                            <div class="flex gap-3 jsutify-start items-center">
                                <i class="large material-icons">account_circle</i>
                                <span>{{ data.data.cust_name }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 hover:bg-gray-200 dark:bg-stone-900 dark:hover:bg-stone-600 focus:bg-sky-200 dark:focus:bg-sky-200 rounded-tl-lg" sortable="" field="no_do" header="DO Number" style="width: 10%"></Column>
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 hover:bg-gray-200 dark:bg-stone-900 dark:hover:bg-stone-600 focus:bg-sky-200 dark:focus:bg-sky-200 rounded-tl-lg" field="date_do" header="DO Date" style="width: 10%"></Column>
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 hover:bg-gray-200 dark:bg-stone-900 dark:hover:bg-stone-600 focus:bg-sky-200 dark:focus:bg-sky-200 rounded-tl-lg" field="exp_date" header="EXP Date" style="width: 10%"></Column>
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" field="no_so" header="SO Number" sortable style="width: 15%">
                        <template #body="data">
                            <div class="w-full flex flex-col gap">
                                <span class="text-md text-gray-600">{{ data.data.no_so }}</span>
                                <span class="text-xs text-gray-400">{{ data.data.so_date }}</span>
                            </div>
                        </template>
                    </Column>                    
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" field="no_contract" header="Contract" style="width: 15%">
                        <template #body="data">
                            <div class="w-full flex flex-col gap">
                                <span class="text-md text-gray-600">{{ data.data.no_contract }}</span>
                                <span class="text-xs text-gray-400">{{ data.data.contract_date }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 hover:bg-gray-200 dark:bg-stone-900 dark:hover:bg-stone-600 focus:bg-sky-200 dark:focus:bg-sky-200" header="Status" style="width: 15%">
                        <template #body="data">
                            <div class="w-full flex justify-center">
                                <span v-if="data.data.f_confirm" class="text-md py-1 px-3 bg-emerald-400 text-white rounded-full flex items-center gap-1">
                                    <i class="material-icons text-sm">check</i>
                                    <span>Approved</span>
                                </span>
                                <span v-if="!data.data.f_confirm" class="text-md py-1 px-3 bg-amber-400 text-white rounded-full flex items-center gap-1">
                                    <i class="material-icons text-sm">access_time</i>
                                    <span>Waiting</span>
                                </span>
                            </div>
                        </template>
                    </Column>
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="bg-gray-300 dark:bg-stone-900 rounded-tr-lg" header="Action" style="width: 10%">
                        <template #body="data">
                            <button @click="this.$router.push(`/do-details/${data.data.no_do}`)" class="text-md py-1 px-1 bg-gray-600 hover:bg-gray-900 text-white rounded-sm flex items-center">
                                <i class="material-icons">view_list</i>
                            </button>
                        </template>
                    </Column>
                </DataTable>
            </div>
            
        </div>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Skeleton from 'primevue/skeleton';
import InputGroup from 'primevue/inputgroup';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { useAuthStore } from '@/store/auth';
import axios from 'axios';

export default {
    name: 'OrderView',
    components: {
        DataTable,
        Column,
        Skeleton,
        InputGroup,
        Button,
        InputText,    
    },
    data(){
        return {
            loading: true,
            toast: null,
            token: null,
            distorders: [],
            searchValue: null,
            dorder: [],
        }
    },
    created(){
        this.toast = useToast();
        const authStore = useAuthStore()
        this.token = authStore.getAuthToken;

        this.fetchDistributionOrder()
    },
    methods:{
        searching(){
            this.items = [];
            const searchTerm = "*" + this.searchValue + "*";
            const wildcardRegex = new RegExp(
                "^" + searchTerm.replace(/\*/g, ".*") + "$",
                "i"
            );

            const matchingObjects = this.dorder.filter((obj) =>
                Object.values(obj).some(
                    (value) => typeof value === "string" && wildcardRegex.test(value)
                )
            );

            this.distorders = matchingObjects;
        },
        async fetchDistributionOrder(){
            try {
                const {data} = await axios.get(`/get-all-do/${this.token}`)
                this.dorder = data
                this.distorders = data

                this.loading = false;
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'br', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout()
            }
        }
    }
}
</script>

<style>

</style>