<template>
    <div class="w-full h-full bg-stone-100 pt-5">
       <div class="px-5">
           <div class="mb-2">
               <a @click="this.$router.go(-1)" class="focus:outline-none hover:underline text-gray-500 text-sm cursor-pointer"><i class="mdi mdi-arrow-left text-gray-400"></i>Back</a>
           </div>
           <div class="mb-2">
               <h1 v-if="!loading" class="text-3xl md:text-5xl font-bold text-gray-600">{{ upperCaseFirst(this.$route.name) }}.</h1>

               <div v-if="loading" class="mt-4 mb-1">
                    <Skeleton width="420px" height="40px"></Skeleton>
                </div>
           </div>
           <div class="mb-5 text-gray-400">
                <div v-if="!loading">
                    <router-link :to="{name: 'dashboard'}" class="focus:outline-none hover:underline text-gray-500">Home</router-link> / <router-link :to="{name: 'sales order'}" class="focus:outline-none hover:underline text-gray-500">Sales Order</router-link> / <router-link :to="{name: 'sales order detail', params: {id: this.$route.params.id}}" class="focus:outline-none hover:underline text-gray-500">Details</router-link>
                </div>

                <div v-if="loading" class="mt-4 mb-1">
                    <Skeleton width="130px" height="15px"></Skeleton>
                </div>
           </div>
       </div>
       
       <div class="relative w-full bg-white border-t border-gray-200 text-gray-800">
        <div class="relative mx-auto w-full bg-white">
            <div class="grid min-h-screen xl:grid-cols-1 2xl:grid-cols-10">
                <div class="relative col-span-full py-3 px-4 sm:py-12 md:col-span-5 2xl:col-span-6 lg:py-12">
                    <span v-if="salesorder.f_status == 'APPROVED'" class="absolute right-5 top-3 text-3xl text-emerald-400">Confirmed</span>

                    <div v-if="!loading && !isEdit && salesorder.f_status == ''" @click="editPage" class="absolute top-[5px] right-[7px] z-10 rounded-md w-[32px] h-[32px] bg-teal-400 hover:bg-teal-600 cursor-pointer flex items-center justify-center text-white">
                        <svg class="w-[22px] h-[22px]" fill="#ffffff" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="796 796 200 200" enable-background="new 796 796 200 200" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M989.732,874.138c-3.462,0-6.268,2.806-6.268,6.268v91.41c0,6.423-5.227,11.648-11.648,11.648H820.184 c-6.423,0-11.648-5.226-11.648-11.648v-151.63c0-6.423,5.226-11.648,11.648-11.648h95.599c3.462,0,6.268-2.806,6.268-6.268 c0-3.461-2.806-6.268-6.268-6.268h-95.599c-13.335,0-24.184,10.849-24.184,24.184v151.63c0,13.335,10.849,24.184,24.184,24.184 h151.632c13.334,0,24.184-10.849,24.184-24.184v-91.41C996,876.944,993.193,874.138,989.732,874.138z"></path> <path d="M829.716,963.686h49.934l107.182-107.182c9.43-9.43,9.43-24.772,0-34.203l-15.731-15.732 c-4.567-4.567-10.641-7.083-17.101-7.083c-6.461,0-12.533,2.516-17.102,7.083l-107.182,107.18V963.686z M874.654,950.955 l-32.208-32.207l78.466-78.465l32.207,32.207L874.654,950.955z M945.762,815.434c2.201-2.2,5.127-3.412,8.238-3.412 s6.037,1.211,8.237,3.412l15.73,15.731c4.544,4.542,4.544,11.933,0.001,16.475l-12.924,12.924c-1.69,1.691-4.433,1.691-6.125,0 l-26.081-26.082c-1.691-1.691-1.691-4.434,0-6.125L945.762,815.434z"></path> </g> </g></svg>
                    </div>

                    <div class="relative mx-auto w-full px-4">
                        <span class="absolute top-0 right-[10px] text-lg text-gray-400 font-normal">{{ salesorder.so_date }}</span>

                        <div v-if="loading" class="absolute -top-[8px] right-0 mt-4 mb-1">
                            <Skeleton width="110px" height="15px"></Skeleton>
                        </div>

                        <h5 v-if="!loading" class="relative text-xl text-gray-400 font-bold">#{{ salesorder.no_so }}</h5>

                        <div v-if="loading" class="mt-2 mb-1">
                            <Skeleton width="135px" height="15px"></Skeleton>
                        </div>

                        <h1 v-if="!loading" class="relative text-2xl font-medium text-gray-700 sm:text-3xl">{{ salesorder.cust_name }}<span class="mt-2 block h-1 w-10 bg-teal-600 sm:w-20"></span></h1>

                        <div v-if="loading" class="mt-2 mb-1">
                            <Skeleton width="380px" height="30px"></Skeleton>
                        </div>
                    
                        <div class="w-full mt-6">
                            <div class="py-4 border-b border-gray-200 flex items-center justify-between">
                                <p class="text-base leading-4 text-gray-800 dark:text-gray-300">
                                    <span v-if="!loading">Contract</span>
                                    
                                    <Skeleton class="mt-5" v-if="loading" width="90px" height="10px"></Skeleton>
                                </p>
                                <div class="flex items-center justify-center">
                                    <p v-if="!loading" class="text-sm leading-none text-gray-600 dark:text-gray-300">
                                        {{ salesorder.no_contract }}
                                    </p>

                                    <Skeleton class="mt-5" v-if="loading" width="90px" height="10px"></Skeleton>
                                </div>
                            </div>

                            <div class="py-4 border-b border-gray-200 flex items-center justify-between">
                                <p class="text-base leading-4 text-gray-800 dark:text-gray-300">
                                    <span v-if="!loading">PO Number</span>

                                    <Skeleton class="mt-2" v-if="loading" width="90px" height="10px"></Skeleton>
                                </p>
                                <div class="flex items-center justify-center">
                                    <p v-if="!loading" class="text-sm leading-none text-gray-600 dark:text-gray-300">
                                        {{ salesorder.no_po }}
                                    </p>

                                    <Skeleton class="mt-2" v-if="loading" width="90px" height="10px"></Skeleton>
                                </div>
                            </div>

                            <div class="py-4 border-b border-gray-200 flex items-center justify-between">
                                <p class="text-base leading-4 text-gray-800 dark:text-gray-300">
                                    <span v-if="!loading">PO Date</span>

                                    <Skeleton class="mt-1" v-if="loading" width="90px" height="10px"></Skeleton>
                                </p>
                                <div class="flex items-center justify-center">
                                    <p v-if="!loading" class="text-sm leading-none text-gray-600 dark:text-gray-300">
                                        {{ salesorder.po_date }}
                                    </p>

                                    <Skeleton class="mt-1" v-if="loading" width="90px" height="10px"></Skeleton>
                                </div>
                            </div>
                            
                            <div>
                                <div class="border-b py-4 mt-7 border-gray-200">
                                    <div
                                    data-menu
                                    class="flex justify-between items-center cursor-pointer"
                                    >
                                        <p v-if="!loading" class="text-base leading-4 text-gray-800 dark:text-gray-300">
                                            Address
                                        </p>

                                        <Skeleton class="mt-[19px]" v-if="loading" width="70px" height="10px"></Skeleton>
                                    </div>
                                    <div
                                    class="pt-4 text-base leading-normal pr-12 mt-4 text-gray-600 dark:text-gray-300"
                                    id="sect"
                                    >

                                    <span v-if="!loading">{{ salesorder.cust_alamat }}</span>

                                    <Skeleton class="mt-0" v-if="loading" width="80%" height="10px"></Skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button v-if="!loading && !isEdit && salesorder.f_status == ''" @click="approveSO" type="submit" class="mt-4 inline-flex w-full items-center justify-center rounded bg-teal-600 hover:bg-teal-800 py-2.5 px-4 text-base font-semibold tracking-wide text-white text-opacity-80 outline-none ring-offset-2 transition hover:text-opacity-100 focus:ring-2 focus:ring-teal-500 sm:text-lg gap-2">
                            <i class="large material-icons">lock</i>
                            Confirm
                        </button>
                        
                        <button v-if="!loading && isEdit && salesorder.f_status == ''" @click="saveWork" type="button" class="mt-4 inline-flex w-full items-center justify-center rounded bg-teal-600 hover:bg-teal-800 py-2.5 px-4 text-base font-semibold tracking-wide text-white text-opacity-80 outline-none ring-offset-2 transition hover:text-opacity-100 focus:ring-2 focus:ring-teal-500 sm:text-lg gap-2">
                            <i class="large material-icons">save</i>
                            Save Work
                        </button>
                        
                        <div v-if="loading && salesorder.f_status == ''" class="relative mt-6">
                            <Skeleton width="100%" height="44px"></Skeleton>
                        </div>
                    </div>
                </div>

                <div v-if="!loading" class="relative col-span-full flex flex-col py-6 pl-8 pr-4 sm:py-12 md:col-span-5 2xl:col-span-4 lg:py-24">
                    <div class="absolute w-[90%] z-10 top-5 mx-auto">
                        <div class="relative flex items-center text-gray-400 focus-within:text-emerald-400">
                            <span class="absolute left-4 h-6 flex items-center pr-3 border-r border-gray-300">
                                <svg xmls="http://ww50w3.org/2000/svg" class="w-4 fill-current" viewBox="0 0 35.997 36.004">
                                    <path id="Icon_awesome-search" data-name="search" d="M35.508,31.127l-7.01-7.01a1.686,1.686,0,0,0-1.2-.492H26.156a14.618,14.618,0,1,0-2.531,2.531V27.3a1.686,1.686,0,0,0,.492,1.2l7.01,7.01a1.681,1.681,0,0,0,2.384,0l1.99-1.99a1.7,1.7,0,0,0,.007-2.391Zm-20.883-7.5a9,9,0,1,1,9-9A8.995,8.995,0,0,1,14.625,23.625Z"></path>
                                </svg>
                            </span>
                            
                            <input v-if="!loading" v-model="searchValue" @input="searching" type="search" name="leadingIcon" id="leadingIcon" placeholder="Search here" class="w-full pl-14 pr-4 py-2.5 rounded-xl text-sm text-gray-600 outline-none border border-gray-300 focus:border-emerald-400 transition">
                        </div>
                    </div>

                    <h2 class="text-2xl text-white z-10">Order Summary</h2>
                    <div>
                        <img src="/images/bg-login.jpg" alt="" class="absolute inset-0 h-full w-full object-cover" />
                        <div class="absolute inset-0 h-full w-full bg-gradient-to-t from-teal-800 to-teal-400 opacity-95"></div>
                    </div>
                    <div class="relative mt-4">
                        <ul class="space-y-5 
                                w-full 
                                md:h-[420px] 
                                2xl:h-[600px] 
                                overflow-auto 
                                pr-2 
                                [&::-webkit-scrollbar]:w-2
                                [&::-webkit-scrollbar-track]:rounded-full
                                [&::-webkit-scrollbar-track]:bg-teal-600
                                [&::-webkit-scrollbar-thumb]:rounded-full
                                [&::-webkit-scrollbar-thumb]:bg-teal-800
                                dark:[&::-webkit-scrollbar-track]:bg-neutral-700
                                dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                            <li v-for="item in items" :key="item.no_so" class="relative flex justify-between">
                                <div class="inline-flex">
                                    <img src="../assets/images/no_image.jpg" alt="" class="md:w-[80px] md:w-[50px] 2xl:w-[100px] 2xl:h-[100px] rounded-lg object-cover" />
                                    <div class="ml-3">
                                        <p class="md:text-xs 2xl:text-sm font-normal text-gray-200">{{ item.itemno }}</p>
                                        <p class="md:text-sm 2xl:text-xl font-semibold text-white">{{ item.itemdesc }}</p>
                                        <p class="md:text-xs 2xl:text-sm text-white text-opacity-80">{{ item.kategori_nm }}</p>
                                        <p class="md:text-xs 2xl:text-sm font-semibold text-gray-200 2xl:mt-2">Qty: <span class="text-white">{{ item.qty_order }} {{ upperCaseFirst(item.kdstn) }}</span></p>

                                        <form v-if="isEdit" class="absolute right-0 bottom-0 max-w-xs mx-auto">
                                            <div class="relative flex items-center max-w-[100px]">
                                                <button @click="minQty(item)" type="button" id="decrement-button" data-input-counter-decrement="bedrooms-input" class="bg-transparent border-2 border-white hover:bg-teal-600 rounded-s-lg p-2 h-[30px] focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                                                    <svg class="w-3 h-3 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>
                                                    </svg>
                                                </button>
                                                
                                                <input v-model="item.qty_order" type="text" id="bedrooms-input" data-input-counter data-input-counter-min="1" data-input-counter-max="5" aria-describedby="helper-text-explanation" class="bg-transparent border-t-2 border-b-2 border-white h-[30px] font-medium text-center text-white text-sm block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-none focus:outline-none" placeholder="" required />
                                                
                                                <button @click="addQty(item)" type="button" id="increment-button" data-input-counter-increment="bedrooms-input" class="bg-transparent border-2 border-white hover:bg-teal-600 rounded-e-lg p-2 h-[30px] focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                                                    <svg class="w-3 h-3 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end">
                                    <div class="flex flex-row">
                                        <p v-if="!isEdit" class="text-md font-semibold text-teal-600 bg-white px-2 rounded-lg">Disc {{ item.disc_akum }}%</p>
                                       
                                        <div v-if="isEdit" class="bg-white w-[90px] px-2 rounded-lg flex flex-row border border-rose-400">
                                            <span class="text-md font-semibold text-teal-600 w-[40px]">Disc</span>
                                            <input v-model="item.disc_akum" type="text" class="w-[32px] font-semibold px-1 text-teal-600 rounded-lg text-end"/>
                                            <span class="text-teal-600 font-semibold">%</span>
                                        </div>
                                    </div>
                                    
                                    <p class="md:text-lg 2xl:text-2xl font-semibold text-white">Rp. {{ formatPrice(item.harga_satuan) }}</p>
                                    <p v-if="item.ppn_rp > 0" class="md:text-xs 2xl:text-md font-semibold text-amber-400">Vat +Rp. {{ formatPrice(item.ppn_rp) }}</p>
                                </div>
                            </li>

                            <li v-if="items.length <= 0" class="relative flex justify-between">
                                <div class="w-full h-[40px] inline-flex text-white justify-center item-center text-xl gap-2">
                                    <i class="large material-icons mt-[3px]">error</i>    
                                    <span class="">No items Found</span>
                                </div>
                            </li>
                        </ul>

                        <div class="my-5 h-0.5 w-full bg-teal-100"></div>
                        <div class="flex flex-col gap-2">
                            <p class="flex justify-between border-b border-teal-100 text-md font-bold text-white pb-2"><span>Total price:</span><span>Rp. {{ formatPrice(total_dpp) }}</span></p>
                            <p class="flex justify-between text-md font-medium text-white border-b border-teal-100 pb-2"><span>Vat:</span><span>Rp. {{ formatPrice(ppn) }}</span></p>

                            <p class="flex justify-between text-xl font-medium text-white mt-4"><span>Grand Total:</span><span>Rp. {{ formatPrice(ppn + total_dpp) }}</span></p>
                        </div>
                    </div>

                    <div class="relative mt-10 text-white">
                        <h3 class="mb-5 text-lg font-bold">Support</h3>
                        <p class="text-sm font-semibold">+01 653 235 211 <span class="font-light">(International)</span></p>
                        <p class="mt-1 text-sm font-semibold">support@saritirta-group.com <span class="font-light">(Email)</span></p>
                        <p class="mt-2 text-xs font-medium">Call us for technical issues</p>
                    </div>
                    <div class="relative mt-10 flex">
                        <p class="flex flex-col"><span class="text-sm font-bold text-white">Terms & Condition</span><span class="text-xs font-medium text-white">within 30 days of purchase</span></p>
                    </div>
                </div>

                <Skeleton class="mt-0 md:col-span-5 2xl:col-span-4" style="border-radius: 0px;" v-if="loading" width="100%" height="100%"></Skeleton>
            </div>
        </div>
       </div>

       <ToastVue position="bottom-right" group="dtso" />
       
       <ConfirmDialog group="dialog">
            <template #message="slotProps">
                <div class="flex flex-col items-center w-full gap-4 border-b border-surface-200 dark:border-surface-700 pb-2">
                    <i :class="slotProps.message.icon" class="!text-6xl text-yellow-400"></i>
                    <p>{{ slotProps.message.message }}</p>
                </div>
            </template>
        </ConfirmDialog>
   </div>
</template>

<script>
import axios from 'axios';
// import CryptoJS from 'crypto-js';
import Skeleton from 'primevue/skeleton';
import { useToast } from 'primevue/usetoast';
import { useAuthStore } from '@/store/auth';

export default {
    name: 'SaleOrderDetailView',
    components: {
        Skeleton,
    },
    data(){
        return {
            salesorder: {
                no_so: null,
                so_date: null,
                cust_name: null,
                no_po: null,
                po_date: null,
                f_status: null,
                total_dpp: 0,
                ppn: 0,
                items: []
            },
            isEdit: false,
            items: [],
            searchValue: null,
            loading: true,
            toast: null,
            token: null,
            total_dpp: 0,
            ppn: 0,
        }
    },
    mounted(){
        this.toast = useToast()

        const authStore = useAuthStore()
        this.token = authStore.getAuthToken

        this.fetchSalesOrder()
        setTimeout(() => {
            this.loading = false;
        }, 3000)
    },
    methods: {
        editPage(){
            this.isEdit = true;
        },
        searching(){
            this.items = [];
            const searchTerm = "*" + this.searchValue + "*";
            const wildcardRegex = new RegExp(
                "^" + searchTerm.replace(/\*/g, ".*") + "$",
                "i"
            );

            const matchingObjects = this.salesorder.items.filter((obj) =>
                Object.values(obj).some(
                    (value) => typeof value === "string" && wildcardRegex.test(value)
                )
            );

            this.items = matchingObjects;
        },
        async fetchSalesOrder(){
            try {
                // const secretKey = '3+3]8:^}H>z'
                // const currentTimestamp = Math.floor((Date.now() + 3 * 60 * 60 * 1000) / 1000) ;
                // const authKey = CryptoJS.enc.Hex.stringify(CryptoJS.SHA256(`${secretKey}${currentTimestamp}`));

                this.total_dpp = 0;
                this.ppn = 0;
                const {data} = await axios.get(`/get-so-detail/${this.$route.params.id}/${this.token}`)

                this.salesorder = data
                this.items = this.salesorder.items

                this.items.forEach((data) => {
                    this.total_dpp += data.qty_order * data.harga_satuan
                    this.ppn = data.ppn * data.qty_order
                })

                this.loading = false;
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'dtso', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout();
            }
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        changeValue(){
            this.salesorder.items = this.items;
        },
        addQty(item){
            let idx;
            this.items.map((data, index) => {
                if(data.itemno === item.itemno){
                    idx = index
                }
            })

            this.items[idx].qty_order += 1;
            this.salesorder.items = this.items;
        },
        minQty(item){
            let idx;
            this.items.map((data, index) => {
                if(data.itemno === item.itemno){
                    idx = index
                }
            })

            this.items[idx].qty_order -= 1;
            this.salesorder.items = this.items;
        },
        async approveSO(){
            try {
                this.$confirm.require({
                    group: 'dialog',
                    header: 'Confirmation',
                    message: 'Are you sure want to confirm this transaction ?',
                    icon: 'pi pi-exclamation-triangle',
                    rejectProps: {
                        label: 'Cancel',
                        icon: 'pi pi-times',
                        outlined: true,
                        size: 'small',
                        severity: 'secondary'
                    },
                    acceptProps: {
                        label: 'Submit',
                        icon: 'pi pi-check',
                        size: 'small',
                        severity: 'success'
                    },
                    accept: async() => {
                        const reqBody = {
                            no_so: this.salesorder.no_so
                        }

                        await axios.post(`/approve-so/${this.token}`, reqBody)
                        .then(() => {
                            this.toast.add({ severity: 'success', summary: 'Success', detail: 'Sales order created.', group: 'dtso', life: 3000 })

                            setTimeout(() => {
                                window.location.reload()
                            }, 1500)
                        })
                        .catch((error) =>{
                            this.toast.add({ severity: 'error', summary: 'Success', detail: error, group: 'dtso', life: 3000 })

                            if(error.response && error.response.status == 401) this.userLogout()
                        })
                    },
                    reject: () => {
                        this.toast.add({ severity: 'info', summary: 'Canceled', detail: 'Operation canceled', group: 'dtso', life: 3000 });
                    }
                });
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'tr', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout();
            }
        },
        async saveWork(){
            try {
                this.$confirm.require({
                    group: 'dialog',
                    header: 'Confirmation',
                    message: 'Are you sure want to save this work ?',
                    icon: 'pi pi-exclamation-triangle',
                    rejectProps: {
                        label: 'Cancel',
                        icon: 'pi pi-times',
                        outlined: true,
                        size: 'small',
                        severity: 'secondary'
                    },
                    acceptProps: {
                        label: 'Submit',
                        icon: 'pi pi-check',
                        size: 'small',
                        severity: 'success'
                    },
                    accept: async() => {
                        const reqBody = {
                            no_so: this.salesorder.no_so,
                            items: this.salesorder.items.map(item => ({ itemno: item.itemno, qty: item.qty_order }))
                        }

                        await axios.put(`/edit-so/${this.token}`, reqBody)
                        .then(() => {
                            this.toast.add({ severity: 'success', summary: 'Success', detail: 'Sales order updated.', group: 'dtso', life: 3000 })
                            this.isEdit = false;
                            setTimeout(() => {
                                window.location.reload()
                            }, 1500)
                        })
                        .catch((error) =>{
                            this.toast.add({ severity: 'error', summary: 'Success', detail: error, group: 'dtso', life: 3000 })

                            if(error.response && error.response.status == 401) this.userLogout()
                        })
                    },
                    reject: () => {
                        this.toast.add({ severity: 'info', summary: 'Canceled', detail: 'Operation canceled', group: 'dtso', life: 3000 });
                    }
                });
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'tr', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout()
            }
        }
    }
}
</script>
