<template>
    <div class="w-full h-full bg-stone-100 pt-5">
        <div class="px-5">
            <div class="mb-2">
                <a @click="this.$router.go(-1)" class="focus:outline-none hover:underline text-gray-500 text-sm cursor-pointer"><i class="mdi mdi-arrow-left text-gray-400"></i>Back</a>
            </div>
            <div class="mb-2">
                <h1 v-if="!loading" class="text-3xl md:text-5xl font-bold text-gray-600">{{ upperCaseFirst(this.$route.name) }}.</h1>

                <div v-if="loading" class="mt-4 mb-1">
                    <Skeleton width="420px" height="40px"></Skeleton>
                </div>
            </div>
            <div class="mb-5 text-gray-400">
                <div v-if="!loading">
                    <router-link :to="{name: 'dashboard'}" class="focus:outline-none hover:underline text-gray-500">Home</router-link> / <router-link :to="{name: 'distribution order'}" class="focus:outline-none hover:underline text-gray-500">Distribution Order</router-link> / <router-link :to="{name: 'create distribution order'}" class="focus:outline-none hover:underline text-gray-500">Create</router-link>
                </div>

                <div v-if="loading" class="mt-4 mb-1">
                    <Skeleton width="130px" height="15px"></Skeleton>
                </div>
            </div>
        </div>
        
        <div class="relative w-full bg-white border-t border-gray-200 px-5 py-10 text-gray-800">
            <div class="grid xl:grid-col-1 2xl:grid-cols-12 px-4">
                <div class="px-4 2xl:col-span-8">
                    <p v-if="!loading" class="text-xl font-medium">Order Summary</p>

                    <div v-if="loading" class="mb-1 mt-2">
                        <Skeleton width="160px" height="15px"></Skeleton>
                    </div>

                    <p v-if="!loading" class="text-gray-400">Check and pick your items.</p>

                    <div v-if="loading" class="mt-[15px]">
                        <Skeleton width="200px" height="10px"></Skeleton>
                    </div>

                    <div class="relative mt-8 space-y-3 rounded-lg bg-white py-4">
                        <DataTable v-if="!loading" class="absolute -top-[40px] bg-transparent" :value="itemdist" selection-mode="multiple" v-model:selection="selectedItem" :rowClass="rowClass" removableSort sortMode="multiple" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50, 100]" tableStyle="min-width: 100%">
                            <Column class="text-sm" headerClass="bg-transparent" style="width: 5%">
                                <template #body="data">
                                    <div class="flex flex-col rounded-lg bg-transparent sm:flex-row">
                                        <img class="h-28 w-32 rounded-md border object-cover object-center" src="../assets/images/no_image.jpg" alt="" />
                                        <div class="relative flex w-full flex-col px-4">
                                            <span class="float-right text-gray-400">{{data.data.itemno}}</span>
                                            <span class="font-semibold">{{ data.data.itemdesc }} - {{ upperCaseFirst(data.data.kategori_nm) }}</span>
                                            <div class="relative w-full flex flex-row gap-3 text-gray-400">
                                                <p class="xl:text-xs 2xl:text-sm font-normal flex items-center">
                                                    <i class="material-icons xl:text-xs 2xl:text-md">shopping_basket</i>
                                                    Order: {{ data.data.qty_order }} {{ upperCaseFirst(data.data.kdstn) }}
                                                </p>
                                                <p class="xl:text-xs 2xl:text-sm font-normal flex items-center">
                                                    <i class="material-icons xl:text-xs 2xl:text-md">call_received</i>
                                                    Complete: {{ data.data.qty_do }} {{ upperCaseFirst(data.data.kdstn) }}
                                                </p>
                                            </div>

                                            <p class="text-lg font-bold text-teal-400">Rp {{ formatPrice(data.data.harga) }}</p>
                                            <p class="text-gray-400 text-sm">VAT <span class="text-rose-400">{{ formatPrice(data.data.ppn) }}</span></p>

                                            <form class="absolute right-0 bottom-0 max-w-xs mx-auto flex flex-col z-50">
                                                <div>
                                                    <p class="w-full text-center mb-1 text-sm">Qty Distribution</p>
                                                    <div class="relative flex items-center max-w-[120px]">
                                                        <input v-model="data.data.qty_input" @change="setQty(data.data.itemno)" type="number" id="bedrooms-input" data-input-counter data-input-counter-min="1" data-input-counter-max="5" aria-describedby="helper-text-explanation" class="bg-gray-100 rounded-lg border border-gray-300 h-8 font-medium text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pb-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pl-4" placeholder="" required />
                                                        <div class="absolute bottom-0 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-400 space-x-1 rtl:space-x-reverse">
                                                            <span>{{ upperCaseFirst(data.data.kdstn) }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </template>
                            </Column>
                        </DataTable>

                        
                        <div v-if="loading" class="relative -mt-[30px]">
                            <Skeleton width="100%" height="20px"></Skeleton>
                        </div>

                        <div v-if="loading" class="relative mt-5">
                            <Skeleton width="100%" height="70px"></Skeleton>
                        </div>
                    </div>

                    
                </div>

                <div class="mt-10 h-auto bg-stone-100 rounded-md px-4 pt-8 lg:mt-0 2xl:col-span-4">
                    <p v-if="!loading" class="text-xl font-medium">Order Details</p>

                    <div v-if="loading" class="mb-1 mt-2">
                        <Skeleton width="130px" height="17px"></Skeleton>
                    </div>

                    <p v-if="!loading" class="text-gray-400">Complete your order by providing your details.</p>

                    <div v-if="loading" class="mb-1 mt-[12px]">
                        <Skeleton width="330px" height="10px"></Skeleton>
                    </div>
                    
                    <div class="">
                        <label v-if="!loading" for="email" class="mt-4 mb-2 block text-sm font-medium">Customer</label>

                        <div v-if="loading" class="mb-1 mt-[27px]">
                            <Skeleton width="80px" height="10px"></Skeleton>
                        </div>

                        <div v-if="!loading" class="relative">
                            <Select v-model="custkd" :options="customers" filter optionLabel="cust_name" placeholder="Select a Customer" class="w-full h-[45px]">
                                <template #value="data">
                                    <div v-if="data.value" class="flex items-center gap-3">
                                        <!--
                                            <img :alt="data.value.cust_name" :src="`${data.value.image}`" class="w-8 rounded-full -mt-[3px]" />
                                        -->
                                        <i class="large material-icons">account_circle</i>
                                        <div>{{ data.value.cust_name }}</div>
                                    </div>
                                </template>
                                <template #option="data">
                                    <div class="flex items-center gap-3">
                                        <!-- 
                                            <img :alt="data.option.cust_name" :src="`${data.option.image}`" class="w-10 rounded-full"/>
                                        -->
                                        <i class="large material-icons">account_circle</i>
                                        <div>{{ data.option.cust_name }}</div>
                                    </div>
                                </template>
                            </Select>

                            <Required v-if="error.customer" />
                        </div>

                        <div v-if="loading" class="mb-1 mt-[13px]">
                            <Skeleton width="100%" height="44px"></Skeleton>
                        </div>
                        
                        <label v-if="!loading" for="card-holder" class="mt-4 mb-2 block text-sm font-medium">Sales Order</label>

                        <div v-if="loading" class="mb-1 mt-[22px]">
                            <Skeleton width="80px" height="10px"></Skeleton>
                        </div>

                        <div v-if="!loading" class="relative">
                            <Select v-model="sono" :options="salesoders" filter optionLabel="no_so" placeholder="Select Sales Order" class="w-full h-[45px]">
                                <template #value="data">
                                    <div v-if="data.value" class="flex items-center gap-3">
                                        <i class="large material-icons">description</i>
                                        <div>{{ data.value.no_so }}</div>
                                    </div>
                                </template>
                                <template #option="data">
                                    <div class="flex items-center gap-3">
                                        <i class="large material-icons">description</i>
                                        <div>{{ data.option.no_so }}</div>
                                    </div>
                                </template>
                            </Select>

                            <Required v-if="error.sono" />
                        </div>

                        <div v-if="loading" class="mb-1 mt-[13px]">
                            <Skeleton width="100%" height="44px"></Skeleton>
                        </div>

                        <label v-if="!loading" for="card-holder" class="mt-4 mb-2 block text-sm font-medium">Expired Date</label>

                        <div v-if="loading" class="mb-1 mt-[22px]">
                            <Skeleton width="80px" height="10px"></Skeleton>
                        </div>

                        <div v-if="!loading" class="relative">
                            <DatePicker v-model="distorder.exp_date" showIcon fluid iconDisplay="input" required="true" :invalid="distorder.exp_date == null" />

                            <Required v-if="error.exp_date" />
                        </div>

                        <div v-if="loading" class="mb-1 mt-[10px]">
                            <Skeleton width="100%" height="44px"></Skeleton>
                        </div>

                        <div class="mt-6 border-t border-b py-2">
                            <div class="flex items-center justify-between">
                                <p v-if="!loading" class="text-sm font-medium text-gray-900">Subtotal</p>
                                <div v-if="loading" class="mt-[8px]">
                                    <Skeleton width="80px" height="10px"></Skeleton>
                                </div>

                                <p v-if="!loading" class="font-semibold text-gray-900">Rp. {{ formatPrice(total_dpp) }}</p>
                                <div v-if="loading" class="mt-[8px]">
                                    <Skeleton width="130px" height="10px"></Skeleton>
                                </div>
                            </div>

                            <div class="flex items-center justify-between">
                                <p v-if="!loading" class="text-sm font-medium text-gray-900">Vat</p>
                                <div v-if="loading" class="mt-[15px] mb-2">
                                    <Skeleton width="50px" height="10px"></Skeleton>
                                </div>

                                <p v-if="!loading" class="font-semibold text-gray-900">Rp. {{ formatPrice(ppn_rp) }}</p>
                                <div v-if="loading" class="mt-[15px] mb-2">
                                    <Skeleton width="130px" height="10px"></Skeleton>
                                </div>
                            </div>
                        </div>

                        <div class="mt-6 flex items-center justify-between">
                            <p v-if="!loading" class="text-sm font-medium text-gray-900">Total</p>
                            <div v-if="loading" class="mt-[12px] mb-2">
                                <Skeleton width="70px" height="10px"></Skeleton>
                            </div>

                            <p v-if="!loading" class="text-2xl font-semibold text-gray-900">Rp. {{ formatPrice(grandtotal) }}</p>
                            <div v-if="loading" class="mt-[12px] mb-2">
                                <Skeleton width="130px" height="10px"></Skeleton>
                            </div>
                        </div>
                    </div>

                    <button v-if="!loading" @click="submitDO" class="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white">Create Distribution Order</button>

                    <div v-if="loading" class="mt-[18px] mb-[30px]">
                        <Skeleton width="100%" height="44px"></Skeleton>
                    </div>
                </div>  
        </div>
    </div>

    <ToastVue position="bottom-right" group="crdo" />

    <ConfirmDialog group="dialog">
        <template #message="slotProps">
            <div class="flex flex-col items-center w-full gap-4 border-b border-surface-200 dark:border-surface-700 pb-2">
                <i :class="slotProps.message.icon" class="!text-6xl text-yellow-400"></i>
                <p>{{ slotProps.message.message }}</p>
            </div>
        </template>
    </ConfirmDialog>
</div>
</template>

<script>
import Select from 'primevue/select';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import axios from 'axios';
import { useToast } from 'primevue/usetoast';
import { useAuthStore } from '@/store/auth';
import DatePicker from 'primevue/datepicker';
import Required from '@/components/Required.vue';
import Skeleton from 'primevue/skeleton';

export default {
    name: 'DeistributionOrder',
    components: {
        Select,
        DataTable,
        Column,
        DatePicker,
        Required,
        Skeleton,
    },
    data(){
        return{
            distorder: {
                customer: null,
                sono: null,
                contract: null,
                do_date: null,
                exp_date: null,
            },
            error: {
                customer: false,
                sono: false,
                do_date: false,
                exp_date: false,
            },
            customers: [],
            salesoders: [],
            custkd: null,
            sono: null,
            itemdist: [],
            token: null,
            toast: null,
            total_dpp: 0,
            ppn_rp: 0,
            grandtotal: 0,
            selectedItem: [],
            tempItems: [],
            loading: true,
        }
    },
    watch: {
        selectedItem(){
            this.tempItems = []
            this.selectedItem.forEach((data) => {
                this.tempItems.push(data.itemno)
            })
        },
        custkd(){
            this.distorder.customer = this.custkd.cust_kd
            this.fetchSalesOrder()
        },
        sono(){
            this.total_dpp = 0
            this.ppn_rp = 0
            this.grandtotal = 0
            this.distorder.sono = this.sono.no_so
            this.distorder.contract = this.sono.no_contract
            
            this.sono.items.forEach((data) => {
                data.qty_input = 0;
                this.total_dpp += data.harga * data.qty_order
                this.ppn_rp += data.ppn_rp * data.qty_order
                this.grandtotal = this.total_dpp + this.ppn_rp
            })

            this.itemdist = this.sono.items
        }
    },
    created(){
        const authStore = useAuthStore()
        this.token = authStore.getAuthToken
        this.toast = useToast()

        this.fetchCustomers()
    },
    methods: {
        rowClass(data) {
            return data === this.selectedItem ? 'bg-rose-400' : 'bg-gray-100';
        },
        setQty(itemno){
            this.itemdist.forEach((data) => {
                if(data.itemno === itemno && (data.qty_order - data.qty_do) < data.qty_input){
                    data.qty_input = data.qty_order - data.qty_do
                }
            })
        },
        async fetchCustomers() {
            try {
                const {data} = await axios.get(`/get-cust/${this.token}`)

                this.customers = data

                this.loading = false;
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'crdo', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout()
            }
        },
        async fetchSalesOrder() {
            try {
                const {data} = await axios.get(`/get-so-by-cust/${this.distorder.customer}/${this.token}`)

                this.salesoders = data
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'crdo', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout()
            }
        },
        async submitDO(){
            try {
                this.clearObject(this.error)

                if(!this.distorder.customer) {
                    this.error.customer = true;

                    setTimeout(() => {
                        this.error.customer = false;
                    }, 5000)

                    return;
                }

                if(!this.distorder.sono) {
                    this.error.sono = true;

                    setTimeout(() => {
                        this.error.sono = false;
                    }, 5000)

                    return;
                }

                if(!this.distorder.exp_date) {
                    this.error.exp_date = true;

                    setTimeout(() => {
                        this.error.exp_date = false;
                    }, 5000)

                    return;
                }

                this.$confirm.require({
                    group: 'dialog',
                    header: 'Confirmation',
                    message: 'Are you sure want to submit this transaction ?',
                    icon: 'pi pi-exclamation-triangle',
                    rejectProps: {
                        label: 'Cancel',
                        icon: 'pi pi-times',
                        outlined: true,
                        size: 'small',
                        severity: 'secondary'
                    },
                    acceptProps: {
                        label: 'Submit',
                        icon: 'pi pi-check',
                        size: 'small',
                        severity: 'success'
                    },
                    accept: async() => {
                        const reqBody = {
                            so_no: this.distorder.sono,
                            do_date: this.distorder.do_date,
                            exp_date: this.distorder.exp_date,
                            cust_kd: this.distorder.customer,
                            cont_kd: this.distorder.contract,
                            items: this.itemdist.filter(item => item.qty_input > 0).map(item => ({ itemno: item.itemno, qty_input: item.qty_input }))
                        }

                        if(reqBody.items.length <= 0) {
                            this.toast.add({ severity: 'info', summary: 'Canceled', detail: 'Operation canceled', group: 'crso', life: 3000 });
                            return;
                        }

                        await axios.post(`/post-do/${this.token}`, reqBody)
                        this.toast.add({ severity: 'success', summary: 'Success', detail: 'Distribution order created.', group: 'crdo', life: 3000 });

                        setTimeout(() => {
                            this.$router.push({name: 'distribution order'})
                        }, 1500);
                    },
                    reject: () => {
                        this.toast.add({ severity: 'info', summary: 'Canceled', detail: 'Operation canceled', group: 'crso', life: 3000 });
                    }
                });
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'crdo', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout();
            }
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
    }
}
</script>

<style>

</style>