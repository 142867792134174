import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import PrimeVue from 'primevue/config';
// import lara from './presets/lara'
import Aura from '@primevue/themes/aura';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ToastService from 'primevue/toastservice';
import Popover from 'primevue/popover';
import Skeleton from 'primevue/skeleton';
import Spinner from './components/Spinner.vue';
import Toast from 'primevue/toast';
import Tooltip from 'primevue/tooltip';
import Particles from "vue3-particles";
import { useAuthStore } from './store/auth'
import 'primeicons/primeicons.css'
import './main.css'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App)

axios.defaults.baseURL = 'https://erp-api.saritirta-group.com/saritirta/web'
// axios.defaults.baseURL = 'http://172.30.15.68:9152/saritirta/web'
// axios.defaults.baseURL = 'http://103.66.198.46:8312/saritirta/web'
// axios.defaults.baseURL = 'https://erp-api-dev.saritirta-group.com/saritirta/web'
// app.config.globalProperties.bff = 'http://172.30.15.23:8000/v1'

app.config.globalProperties.userLogout = () => {
    const authStore = useAuthStore()
    const profile = authStore.getProfile

    const reqBody = {
        userid: profile.userid,
        token: authStore.getAuthToken
    }

    authStore.logout(reqBody)
    authStore.setLogout()

    window.location.href = '/login'
}

app.config.globalProperties.upperCaseFirst = (words) => {
    const string = String(words).toLocaleLowerCase()
    return String(string).split(' ').map(word =>
        word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
};

app.config.globalProperties.formatPrice = (value) => {
    let val = (value/1).toFixed(0).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
};

app.config.globalProperties.formatDate = (dateString) => {
        const [day, month, year] = String(dateString).split('/');
      
        const monthNames = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
      
        const monthName = monthNames[parseInt(month, 10) - 1];
      
        return `${day} ${monthName} ${year}`;
}

app.config.globalProperties.clearObject = (object) => {
    const hasOwn = Object.prototype.hasOwnProperty;
    for (let key in object) {
        if (hasOwn.call(object, key)) {
            let value = object[key];
            if (typeof value === 'string') {
                object[key] = null;
            } else if (typeof value === 'number') {
                object[key] = 0;
            } else if (typeof value === 'boolean') {
                object[key] = false;
            } else if (Array.isArray(value)) {
                object[key] = [];
            } else if (typeof value === 'object' && value !== null) {
                object[key] = {};
            } else {
                object[key] = null;
            }
        }
    }
}

app.component('SpinnerVue', Spinner)
app.component('ToastVue', Toast)
app.component('ConfirmPopup', ConfirmPopup)
app.component('ConfirmDialog', ConfirmDialog)
app.component('PopOver', Popover);
app.component('SkletonVue', Skeleton);
app.directive('tooltip', Tooltip);

app.use(PrimeVue, {
    theme: {
        preset: Aura,
        options: {
            prefix: 'p',
            darkModeSelector: 'sector',
            cssLayer: false
        }
    }
});

app.use(Particles)
app.use(ConfirmDialog)
app.use(ConfirmationService)
app.use(ToastService)
app.use(Spinner)
app.use(createApp)
app.use(store)
app.use(pinia)
app.use(router)
app.mount('#app')