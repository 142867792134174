import { defineStore } from "pinia";
import { parse, stringify } from 'zipson';

export const useSalesStore = defineStore('sales', {
    state: () => ({
        customer: [],        
        contracts: [
            {kontrakno: '11P01', kontrak_date: '30/09/2024'},
            {kontrakno: '11P02', kontrak_date: '28/09/2024'},
            {kontrakno: '11P03', kontrak_date: '05/09/2024'},
            {kontrakno: '11P04', kontrak_date: '12/09/2024'},
            {kontrakno: '11P05', kontrak_date: '18/09/2024'},
            {kontrakno: '11P06', kontrak_date: '21/09/2024'},
            {kontrakno: '11P07', kontrak_date: '21/09/2024'},
            {kontrakno: '11P08', kontrak_date: '21/09/2024'},
            {kontrakno: '11P09', kontrak_date: '21/09/2024'},
        ],
        so: [],
        selectedCustomer: null,
        selectedContract: null,
    }),
    persist: {
        enabled: true,
        storage: localStorage,
        key: 'so-data',
        deserialize: parse,
        serialize: stringify,
        pick: [
            'so', 
            'selectedCustomer',
            'selectedContract',
            'user'
        ],
    },
    getters: {
        getCustomer: (state) => state.customers,
        getContract: (state) => state.contracts,
        getSO: (state) => state.so,
        getSelectedCust: (state) => state.selectedCustomer,
        getSelectedContract: (state) => state.selectedContract,
    },
    actions: {
        setSO(payload) {
            let idx;
            this.so.map((data, index) => {
                if(data.user === payload.user){
                    idx = index
                }
            })

            this.so.splice(idx, 1);
            this.so.push(payload)
        },
        setSelectedCust(payload) {
            this.selectedCustomer = payload
        },
        setSelectedContract(payload){
            this.selectedContract = payload
        }
    } 
})