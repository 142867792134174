<template>
  <div class="grid gap-8 grid-cols-1">
    <div class="flex flex-col">
      <div class="mt-5">
        <form class="form px-4" @submit.prevent="submitForm">
          <div class="md:space-y-2 mb-3">
            <label class="text-xs font-semibold text-gray-600 py-2">Product Name <abbr title="required">*</abbr></label>
            <div class="flex items-center py-6">
                <div class="w-12 h-12 mr-4 flex-none rounded-xl border overflow-hidden">
                  <img class="w-12 h-12 mr-4 object-cover" src="../assets/images/no_image.jpg" alt="Avatar Upload">
                </div>
                <label class="w-full cursor-pointer">
                  <Select v-model="barang" :options="products" filter optionLabel="name" placeholder="Select a Product" class="w-full">
                      <template #value="data">
                          <div v-if="data.value" class="flex items-center">
                              <img :alt="data.value.label" src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" class="w-8" />
                              <div>{{ data.value.itemdesc }}</div>
                          </div>
                          <span v-else>
                              {{ data.placeholder }}
                          </span>
                      </template>
                      <template #option="data">
                          <div class="flex items-center">
                              <img :alt="data.option.label" src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" class="w-8" />
                              <div>{{ data.option.itemdesc }}</div>
                          </div>
                      </template>
                  </Select>
                </label>
              </div>
            </div>
            <div class="md:flex flex-row md:space-x-4 w-full text-xs">
              <div class="relative mb-3 space-y-2 w-full text-xs">
                <label class="font-semibold text-gray-600 py-2">Category <abbr title="required">*</abbr></label>
                <div class="absolute z-10 w-full h-full bg-transparent"></div>
                <input v-model="so.category" placeholder="Category" class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-lg h-10 px-4" required="required" type="text" name="category" id="category">
                <p class="text-red text-xs hidden">Please fill out this field.</p>
              </div>
            </div>
            <div class="md:flex flex-row md:space-x-4 w-full text-xs">
              <div class="mb-3 space-y-2 w-full text-xs">
                <label class="font-semibold text-gray-600 py-2">Quantity <abbr title="required">*</abbr></label>
                <input v-model="so.qty" placeholder="Quantity" class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-lg h-10 px-4" required="required" type="number" name="qty" id="qty">
                <p class="text-red text-xs hidden">Please fill out this field.</p>
              </div>
              <div class="relative mb-3 space-y-2 w-full text-xs">
                <label class="font-semibold text-gray-600 py-2">Unit <abbr title="required">*</abbr></label>
                <div class="absolute z-10 w-full h-full bg-transparent"></div>
                <input v-model="so.unit" placeholder="Unit" class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-gray-300 rounded-lg h-10 px-4" disabled required="required" type="text" name="unit" id="unit">
                <p class="text-red text-xs hidden">Please fill out this field.</p>
              </div>
            </div>
            <div class="relative mb-3 space-y-2 w-full text-xs">
              <label class=" font-semibold text-gray-600 py-2">Discount</label>
              <div v-if="so.f_disc" class="absolute z-10 w-full h-full bg-transparent"></div>
              <div class="flex flex-wrap items-stretch w-full mb-4 relative">
                <div class="flex">
                    <span class="flex leading-normal bg-grey-lighter border-1 rounded-r-none border border-r-0 border-rose-200 px-3 whitespace-no-wrap text-grey-dark text-sm w-12 h-10 bg-rose-400 justify-center items-center  text-xl rounded-lg text-white">
                      <i class="large material-icons">content_cut</i>
                    </span>
                </div>

                <input v-model="so.disc" type="number" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border border-l-0 h-10 border-gray-300 rounded-lg rounded-l-none px-3 relative focus:border-blue focus:shadow" placeholder="15%" required>
              </div>
            </div>
            <div class="relative mb-3 space-y-2 w-full text-xs">
              <label class=" font-semibold text-gray-600 py-2">Price <abbr title="required">*</abbr></label>
              <div class="flex flex-wrap items-stretch w-full mb-4 relative">
                <div class="flex">
                    <span class="flex items-center leading-normal bg-grey-lighter border-1 rounded-r-none border border-r-0 border-green-200 px-3 whitespace-no-wrap text-grey-dark text-sm w-12 h-10 bg-green-200 justify-center items-center  text-xl rounded-lg text-white">
                      <i class="large material-icons">attach_money</i>
                    </span>
                </div>

                <div class="absolute z-10 w-full h-full bg-transparent"></div>
                <input v-model="so.price" type="text" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border border-l-0 h-10 border-gray-300 rounded-lg rounded-l-none px-3 relative focus:border-blue focus:shadow" placeholder="100,000" disabled required>
              </div>
            </div>
            <p class="text-xs text-red-500 text-right my-3">Required fields are marked with an
              asterisk <abbr title="Required field">*</abbr></p>
            <div class="mt-5 text-right md:space-x-3 md:block flex flex-col-reverse">
              <button @click="closeModal" type="button" class="mb-2 md:mb-0 bg-gray-100 px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:bg-gray-400 hover:shadow-sm hover:bg-gray-100 hover:text-white"> Cancel </button>
              <button type="submit" class="mb-2 md:mb-0 bg-teal-600 hover:bg-teal-800 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg">Save</button>
            </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Select from 'primevue/select';
export default {
    name: 'CreateSO',
    props: ["product", "category"],
    components: {
      Select,
    },
    data(){
      return {
        so: {
          itemno: null,
          itemdesc: null,
          category: null,
          f_disc: false,
          disc_item: 0,
          disc: null,
          price: null,
          qty: null,
          unit: null,
          ppn: null,
          ppn_rp: null
        },
        barang: null,
        products: [],
        categories: [],
      }
    },
    watch: {
      barang(){
        this.so.itemno = this.barang.itemno
        this.so.itemdesc = this.barang.itemdesc
        this.so.category = this.barang.kategori_nm
        this.so.price = this.formatPrice(this.barang.harga)
        this.so.unit = this.barang.kdstn
        this.so.ppn = this.barang.ppn
        this.so.ppn_rp = this.barang.ppn_rp
        this.so.f_disc = this.barang.f_disc
        this.so.disc = this.barang.disc ?? 0

        console.log(this.barang)
      }
    },
    created(){
      this.products = this.product
      this.categories = this.category
    },
    methods: {
      closeModal(){
        this.$emit('onClosed', false)
      },
      submitForm(){
        this.so.disc = parseFloat(String(this.so.disc).replaceAll(",","").replaceAll(".",""))
        this.so.price = parseFloat(String(this.so.price).replaceAll(",","").replaceAll(".",""))
        this.$emit('onResolved', this.so)
      }
    }
}
</script>

<style>

</style>