import { defineStore } from "pinia";
import { parse, stringify } from 'zipson';
import axios from "axios";

export const useAuthStore = defineStore('auth', {
    state: () => ({
        authToken: null,
        profile: {
            userid: null,
            division: null,
            subdivision: null,
            department: null,
            groupname: null,
        },
    }),
    persist: {
        enabled: true,
        storage: localStorage,
        key: 'auth-data',
        deserialize: parse,
        serialize: stringify,
        pick: ['authToken', 'profile'],
    },
    getters: {
        getAuthToken: (state) => state.authToken,
        getProfile: (state) => state.profile
    },
    actions: {
        async login(payload) {
            try {
                const { data } = await axios.post('/login-user', payload)
                this.setLoginData(data)

                return null
            } catch(error){
                if (error.response.status == 401) return "Invalid username or password"
                else return error
            }
        },
        async logout(payload){
            try {
                await axios.post('/logout', payload)
                return null
            } catch(error){
                if (error.response.status == 401) return "Invalid username or password"
                else return error
            }
        },
        setLoginData(payload){
            this.authToken = payload.token
            this.profile.userid = payload.userid
            this.profile.division = payload.divisi_nm
            this.profile.subdivision = payload.subdiv_nm
            this.profile.department = payload.dept_nm
            this.profile.groupname = payload.grpname
        },
        setLogout(){
            this.authToken = null
            this.profile.userid = null
            this.profile.userid = null
            this.profile.division = null
            this.profile.subdivision = null
            this.profile.department = null
            this.profile.groupname = null
        }
    }
})