<template>
    <div class="w-full h-full bg-stone-100 pt-5">
        <div class="px-5">
            <div class="mb-2">
                <a @click="this.$router.go(-1)" class="focus:outline-none hover:underline text-gray-500 text-sm cursor-pointer"><i class="mdi mdi-arrow-left text-gray-400"></i>Back</a>
            </div>
            <div class="mb-2">
                <h1 v-if="!loading" class="text-3xl md:text-5xl font-bold text-gray-600">{{ upperCaseFirst(this.$route.name) }}.</h1>

                <div v-if="loading" class="mt-4 mb-1">
                    <Skeleton width="420px" height="40px"></Skeleton>
                </div>
            </div>
            <div class="mb-5 text-gray-400">
                <div v-if="!loading">
                    <router-link :to="{name: 'dashboard'}" class="focus:outline-none hover:underline text-gray-500">Home</router-link> / <router-link :to="{name: 'sales order'}" class="focus:outline-none hover:underline text-gray-500">Sales Order</router-link> / <router-link :to="{name: 'create sales order'}" class="focus:outline-none hover:underline text-gray-500">Create</router-link>
                </div>

                <div v-if="loading" class="mt-4 mb-1">
                    <Skeleton width="180px" height="15px"></Skeleton>
                </div>
            </div>
        </div>
        
        <div class="w-full bg-white border-t border-gray-200 px-5 py-10 text-gray-800">
            <form class="w-full" @submit.prevent="submitSO">
                <div class="-mx-3 md:flex items-start">
                    <div class="px-3 md:w-7/12 lg:pr-10">
                        <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 text-gray-800 font-light mb-6">
                            <div class="w-full p-3 border-b border-gray-200">
                                <div>
                                    <div class="mb-3">
                                        <label v-if="!loading" class="text-gray-600 font-semibold text-sm mb-2 ml-1">Customer</label>

                                        <div v-if="loading" class="mb-2 mt-2">
                                            <Skeleton width="80px" height="10px"></Skeleton>
                                        </div>
                                        
                                        <div class="relative">
                                            <Select v-if="!loading" v-model="custkd" :options="customers" :invalid="!so.customer" filter optionLabel="cust_name" placeholder="Select a Customer" class="w-full h-[45px]">
                                                <template #value="data">
                                                    <div v-if="data.value" class="flex items-center gap-3">
                                                        <!--
                                                        <img :alt="data.value.cust_name" :src="`${data.value.image}`" class="w-8 rounded-full -mt-[3px]" />
                                                        -->
                                                        <i class="large material-icons">account_circle</i>
                                                        <div>{{ data.value.cust_name }}</div>
                                                    </div>
                                                </template>
                                                <template #option="data">
                                                    <div class="flex items-center gap-3">
                                                        <!-- 
                                                        <img :alt="data.option.cust_name" :src="`${data.option.image}`" class="w-10 rounded-full"/>
                                                        -->
                                                        <i class="large material-icons">account_circle</i>
                                                        <div>{{ data.option.cust_name }}</div>
                                                    </div>
                                                </template>
                                            </Select>

                                            <div v-if="error.customer" class="absolute left-[50%] top-12 z-10 w-[180px] h-[45px] rounded-md bg-white shadow-md shadow-gray-200 flex items-center justify-center border border-stone-400 fade-in">
                                                <div class="relative flex flex-row items-center justify-center">
                                                    <img src="../assets/images/icons/exclamation.png" class="relative w-6 h-6 mr-2 z-10" alt="">
                                                    <span class="text-sm font-400 text-gray-900">Please fill out this field.</span>

                                                    <div class="absolute left-6 -top-[12px] rounded-sm before:absolute before:-bottom-2.5 before:-left-5 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-l-2 before:border-t-2 before:border-white before:bg-white"> </div>
                                                    <div class="absolute left-6 -z-1 -top-[12px] rounded-sm before:absolute before:-bottom-2.5 before:-left-5 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-t before:border-r before:border-stone-400 before:bg-white"> </div>
                                                </div>
                                            </div>

                                            <Skeleton v-if="loading" width="100%" height="45px"></Skeleton>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <label v-if="!loading" class="text-gray-600 font-semibold text-sm mb-2 ml-1">Contract</label>

                                        <div v-if="loading" class="mb-2 mt-6">
                                            <Skeleton width="80px" height="10px"></Skeleton>
                                        </div>

                                        <div class="relative">
                                            <Select v-if="!loading" v-model="nocontract" :options="contracts" :invalid="!so.contract" filter optionLabel="no_contract" placeholder="Select a Contract" class="w-full h-[45px]">
                                                <template #value="data">
                                                    <div v-if="data.value" class="flex items-center">
                                                        <i class="large material-icons">assignment</i>
                                                        <div>{{ data.value.no_contract }}</div>
                                                    </div>
                                                    <span v-else>
                                                        {{ data.placeholder }}
                                                    </span>
                                                </template>
                                                <template #option="data">
                                                    <div class="flex items-center">
                                                        <i class="large material-icons">assignment</i>
                                                        <div>{{ data.option.no_contract }}</div>
                                                    </div>
                                                </template>
                                            </Select>

                                            <div v-if="error.contract" class="absolute left-[50%] top-12 z-10 w-[180px] h-[45px] rounded-md bg-white shadow-md shadow-gray-200 flex items-center justify-center border border-stone-400 fade-in">
                                                <div class="relative flex flex-row items-center justify-center">
                                                    <img src="../assets/images/icons/exclamation.png" class="relative w-6 h-6 mr-2 z-10" alt="">
                                                    <span class="text-sm font-400 text-gray-900">Please fill out this field.</span>

                                                    <div class="absolute left-6 z-2 -top-[12px] rounded-sm before:absolute before:-bottom-2.5 before:-left-5 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-l-2 before:border-t-2 before:border-white before:bg-white"> </div>
                                                    <div class="absolute left-6 -z-1 -top-[12px] rounded-sm before:absolute before:-bottom-2.5 before:-left-5 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-t before:border-r before:border-stone-400 before:bg-white"> </div>
                                                </div>
                                            </div>

                                            <Skeleton v-if="loading" width="100%" height="45px"></Skeleton>
                                        </div>
                                    </div>

                                    <div class="relative mb-4">
                                        <label v-if="!loading" class="text-gray-600 font-semibold text-sm mb-2 ml-1">Address</label>

                                        <div v-if="loading" class="mb-2 mt-4">
                                            <Skeleton width="80px" height="10px"></Skeleton>
                                        </div>

                                        <div class="relative">
                                            <textarea v-if="!loading" v-model="so.address" class="w-full h-[100px] px-3 py-2 z-1 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-emerald-400 transition-colors" placeholder="Jl. Ks Tubun 31" type="text" readonly/>
                                            
                                            <div v-if="loading" class="mb-[20px]">
                                                <Skeleton width="100%" height="100px"></Skeleton>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="w-full p-3">
                                <label for="type2" class="flex items-center cursor-pointer">
                                    <div class="lg:h-[45px] xl:h-[85px] 2xl:h-[11px]"></div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    <div class="px-3 md:w-5/12">
                        <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-3 text-gray-800 font-light mb-6">
                            <div class="w-full flex mb-3 items-center">
                                <div class="w-32">
                                    <span v-if="!loading" class="text-gray-600 font-semibold">Requestor</span>
                                    <div v-if="loading" class="mb-2 mt-2">
                                        <Skeleton width="80px" height="10px"></Skeleton>
                                    </div>
                                </div>
                                <div class="flex-grow pl-3">
                                    <span v-if="!loading">{{ upperCaseFirst(profile.userid) }}</span>
                                    <div v-if="loading" class="mb-2 mt-2">
                                        <Skeleton width="100px" height="10px"></Skeleton>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full flex items-center">
                                <div class="w-32">
                                    <span v-if="!loading" class="text-gray-600 font-semibold">Location</span>
                                    <div v-if="loading" class="mb-2 mt-1">
                                        <Skeleton width="110px" height="10px"></Skeleton>
                                    </div>
                                </div>
                                <div class="flex-grow pl-3">
                                    <span v-if="!loading">{{ upperCaseFirst(profile.department) }}, {{upperCaseFirst(profile.subdivision) }} - {{ upperCaseFirst(profile.division) }}</span>
                                    <div v-if="loading" class="mb-2 mt-1">
                                        <Skeleton width="80%" height="10px"></Skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-full">
                            <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 text-gray-800 font-light mb-6">
                                <div class="w-full p-3 border-b border-gray-200">
                                    <div>
                                        <div class="mb-3">
                                            <label v-if="!loading" class="text-gray-600 font-semibold text-sm mb-2 ml-1">PO Number</label>

                                            <div v-if="loading" class="mb-2 mt-2">
                                                <Skeleton width="80px" height="10px"></Skeleton>
                                            </div>

                                            <div class="relative">
                                                <input v-if="!loading" v-model="so.pono" class="w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-emerald-400 transition-colors" :class="{'border-rose-400': so.pono == null}" placeholder="11000001" type="text"/>

                                                <div v-if="error.pono" class="absolute left-[50%] top-12 z-10 w-[180px] h-[45px] rounded-md bg-white shadow-md shadow-gray-200 flex items-center justify-center border border-stone-400 fade-in">
                                                    <div class="relative flex flex-row items-center justify-center">
                                                        <img src="../assets/images/icons/exclamation.png" class="w-6 h-6 mr-2" alt="">
                                                        <span class="text-sm font-400 text-gray-900">Please fill out this field.</span>

                                                        <div class="absolute left-6 -top-[12px] rounded-sm before:absolute before:-bottom-2.5 before:-left-5 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-l-2 before:border-t-2 before:border-white before:bg-white"> </div>
                                                        <div class="absolute left-6 -z-1 -top-[12px] rounded-sm before:absolute before:-bottom-2.5 before:-left-5 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-t before:border-r before:border-stone-400 before:bg-white"> </div>
                                                    </div>
                                                </div>
                                                
                                                <Skeleton v-if="loading" width="100%" height="44px"></Skeleton>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label v-if="!loading" class="text-gray-600 font-semibold text-sm mb-2 ml-1">PO Date</label>
                                            
                                            <div v-if="loading" class="mb-2 mt-4">
                                                <Skeleton width="80px" height="10px"></Skeleton>
                                            </div>

                                            <div class="relative">
                                                <DatePicker v-if="!loading" v-model="so.po_date" showIcon fluid iconDisplay="input" required="true" :invalid="so.po_date == null" />

                                                <div v-if="error.po_date" class="absolute left-[50%] top-12 z-10 w-[180px] h-[45px] rounded-md bg-white shadow-md shadow-gray-200 flex items-center justify-center border border-stone-400 fade-in">
                                                    <div class="relative flex flex-row items-center justify-center">
                                                        <img src="../assets/images/icons/exclamation.png" class="w-6 h-6 mr-2" alt="">
                                                        <span class="text-sm font-400 text-gray-900">Please fill out this field.</span>

                                                        <div class="absolute left-6 -top-[12px] rounded-sm before:absolute before:-bottom-2.5 before:-left-5 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-l-2 before:border-t-2 before:border-white before:bg-white"> </div>
                                                        <div class="absolute left-6 -z-1 -top-[12px] rounded-sm before:absolute before:-bottom-2.5 before:-left-5 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-t before:border-r before:border-stone-400 before:bg-white"> </div>
                                                    </div>
                                                </div>

                                                <Skeleton v-if="loading" width="100%" height="44px"></Skeleton>
                                            </div>
                                        </div>

                                        <div class="mb-3">
                                            <label v-if="!loading" class="text-gray-600 font-semibold text-sm mb-2 ml-1">Expired Date</label>
                                            
                                            <div v-if="loading" class="mb-2 mt-4">
                                                <Skeleton width="80px" height="10px"></Skeleton>
                                            </div>

                                            <div class="relative">
                                                <DatePicker v-if="!loading" v-model="so.exp_date" showIcon fluid iconDisplay="input" required="true" :invalid="so.exp_date == null" />

                                                <div v-if="error.exp_date" class="absolute left-[50%] top-12 z-10 w-[180px] h-[45px] rounded-md bg-white shadow-md shadow-gray-200 flex items-center justify-center border border-stone-400 fade-in">
                                                    <div class="relative flex flex-row items-center justify-center">
                                                        <img src="../assets/images/icons/exclamation.png" class="w-6 h-6 mr-2" alt="">
                                                        <span class="text-sm font-400 text-gray-900">Please fill out this field.</span>

                                                        <div class="absolute left-6 -top-[12px] rounded-sm before:absolute before:-bottom-2.5 before:-left-5 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-l-2 before:border-t-2 before:border-white before:bg-white"> </div>
                                                        <div class="absolute left-6 -z-1 -top-[12px] rounded-sm before:absolute before:-bottom-2.5 before:-left-5 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-t before:border-r before:border-stone-400 before:bg-white"> </div>
                                                    </div>
                                                </div>

                                                <Skeleton v-if="loading" width="100%" height="44px"></Skeleton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="relative w-full flex flex-row gap-6 justify-between">
                    <div class="w-[30%] flex flex-row gap-6">
                        <button v-if="!loading" @click="creatingSO" type="button" class="px-4 py-3 rounded-md bg-teal-600 hover:bg-teal-800 text-white text-md flex items-center gap-3">
                            <i class="large material-icons">queue</i>
                            Add Item
                        </button>

                        <div v-if="error.items" class="absolute left-[2%] top-12 z-10 w-[200px] h-[45px] rounded-md bg-white shadow-md shadow-gray-200 flex items-center justify-center border border-stone-400 fade-in">
                            <div class="relative flex flex-row items-center justify-center">
                                <img src="../assets/images/icons/exclamation.png" class="w-6 h-6 mr-2" alt="">
                                <span class="text-sm font-400 text-gray-900">Please fill out this field.</span>

                                <div class="absolute left-6 -top-[12px] rounded-sm before:absolute before:-bottom-2.5 before:-left-5 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-l-2 before:border-t-2 before:border-white before:bg-white"> </div>
                                <div class="absolute left-6 -z-1 -top-[12px] rounded-sm before:absolute before:-bottom-2.5 before:-left-5 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-t before:border-r before:border-stone-400 before:bg-white"> </div>
                            </div>
                        </div>

                        <div v-if="loading">
                            <Skeleton width="134px" height="48px"></Skeleton>
                        </div>

                        <button v-if="!loading" @click="confirmationDelete($event)" type="button" class="px-4 py-3 rounded-md bg-rose-800 hover:bg-rose-900 text-white text-md flex items-center gap-3">
                            <i class="large material-icons">remove_circle</i>
                            Delete
                        </button>

                        <div v-if="loading">
                            <Skeleton width="114px" height="48px"></Skeleton>
                        </div>
                    </div>

                    <InputGroup v-if="!loading" class="absolute right-10 top-1" style="width: 500px">
                        <Button style="background: rgb(41 37 36);border: none" label="Search" />
                        <InputText v-model="searchItem" @input="searching" class="focus:outline-0 active:outline-0 active:outline-stone-900 focus:outline-stone-900" placeholder="Keyword" />
                    </InputGroup>

                    <div v-if="loading" class="absolute right-[40px] top-[4px]">
                        <Skeleton width="500px" height="42px"></Skeleton>
                    </div>
                </div>

                <div v-if="loading" class="relative mt-2">
                    <Skeleton width="100%" height="70px"></Skeleton>
                </div>

                <div v-if="loading" class="relative mt-5">
                    <Skeleton width="100%" height="70px"></Skeleton>
                </div>

                <DataTable v-if="!loading" class="bg-transparent" :value="soItems" selection-mode="single" v-model:selection="selectedItem" removableSort sortMode="multiple" :rowClass="rowClass" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50, 100]" tableStyle="min-width: 100%">
                    <Column class="h-[60px] text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 hover:bg-gray-200 dark:bg-stone-900 dark:hover:bg-stone-600 focus:bg-sky-200 dark:focus:bg-sky-200 rounded-tl-lg" field="itemno" header="Item NO" style="width: 10%"></Column>
                    <Column class="h-[60px] text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" field="itemdesc" header="Item Name" sortable style="width: 15%"></Column>
                    <Column class="h-[60px] text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 hover:bg-gray-200 dark:bg-stone-900 dark:hover:bg-stone-600 focus:bg-sky-200 dark:focus:bg-sky-200" field="category" header="Category" sortable style="width: 10%"></Column>
                    <Column class="h-[60px] text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" field="unit" header="Unit" style="width: 5%"></Column>
                    <Column class="h-[60px] text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" field="qty" header="Qty" style="width: 5%"></Column>
                    <Column class="h-[60px] text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" field="disc_item" header="Disc Item" style="width: 10%"></Column>
                    <Column class="h-[60px] text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" field="disc" header="Disc Contract" style="width: 10%"></Column>
                    <Column class="h-[60px] text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" header="Price" style="width: 10%">
                        <template #body="data">
                            <span>{{ formatPrice(data.data.price) }}</span>
                        </template>
                    </Column>
                    <Column class="h-[60px] text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" field="ppn" header="PPN %" style="width: 5%"></Column>
                    <Column class="h-[60px] text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" header="PPN Rp" style="width: 10%">
                        <template #body="data">
                            <span>{{ formatPrice(data.data.ppn_rp ?? 0) }}</span>
                        </template>
                    </Column>
                    <Column class="h-[60px] text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" header="Subtotal" style="width: 10%">
                        <template #body="data">
                            <span>{{ formatPrice((data.data.qty * data.data.price) + (data.data.qty * (data.data.ppn_rp ?? 0))) }}</span>
                        </template>
                    </Column>
                </DataTable>

                <div class="relative w-full flex flex-col">
                    <button v-if="!loading" type="submit"
                        class="relative block w-full bg-stone-800 hover:bg-stone-950 focus:bg-stone-950 text-white rounded-md px-3 py-2 font-semibold mt-8 flex items-center justify-center gap-3">
                        <i class="large material-icons">lock</i> Place Order
                    </button>

                    <div v-if="loading" class="mt-[30px] mb-1">
                        <Skeleton width="100%" height="40px"></Skeleton>
                    </div>
                </div>
            </form>
        </div>

        <Dialog v-model:visible="showCreate" maximizable modal header="Add SO Items" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <CreateSO :product="items" @onResolved="captureData" @onClosed="onClosedCreate" />
        </Dialog>

        <ToastVue position="bottom-right" group="crso" />

        <ConfirmPopup group="popup">
            <template #message="slotProps">
                <div class="flex flex-row items-center w-full gap-3 border-b border-surface-200 dark:border-surface-700 p-4 mb-2 pb-2">
                    <i class="pi pi-exclamation-triangle text-6xl text-amber-400"></i>
                    
                    <p>{{ slotProps.message.message }}</p>
                </div>
            </template>
        </ConfirmPopup>

        <ConfirmDialog group="dialog">
            <template #message="slotProps">
                <div class="flex flex-col items-center w-full gap-4 border-b border-surface-200 dark:border-surface-700 pb-2">
                    <i :class="slotProps.message.icon" class="!text-6xl text-yellow-400"></i>
                    <p>{{ slotProps.message.message }}</p>
                </div>
            </template>
        </ConfirmDialog>
    </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import DatePicker from 'primevue/datepicker';
import CreateSO from '@/components/CreateSO.vue';
import Select from 'primevue/select';
import InputGroup from 'primevue/inputgroup';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import { useAuthStore } from '@/store/auth';
import axios from 'axios';
import { useToast } from "primevue/usetoast";
import { useSalesStore } from '@/store/sales';

export default {
    name: 'CreateOrder',
    components: {
        Dialog,
        DataTable,
        Column,
        DatePicker,
        CreateSO,
        Select,
        InputGroup,
        Button,
        InputText,
        Skeleton,
    },
    data(){
        return {
            so: {
                customer: null,
                contract: null,
                address: null,
                pono: null,
                po_date: null,
                exp_date: null,
                items: [],
            },
            custkd: null,
            nocontract: null,
            kontrakno: null,
            selectedItem: {
                itemno: null,
                itemdesc: null,
                category: null,
                disc_item: 0,
                disc_contract: 0,
                price: null,
                qty: null,
                unit: null,
                ppn: null,
                ppn_rp: null
            },
            error: {
                pono: false,
                exp_date: false,
                po_date: false,
                customer: false,
                contract: false,
                items: false,
            },
            soItems: [],
            customers: [],
            contracts: [],
            items: [],
            orders: [],
            showCreate: false,
            loading: true,
            toast: null,
            confirm: null,
            token: null,
            authStore: null,
            salesStore: null,
            searchItem: null,
            profile: null,
        }
    },
    watch: {
        custkd() {
            if(this.custkd.f_kontrak) {
                this.fetchContracts(this.token, this.custkd.cust_kd, null)
            } else {
                this.fetchAllProducts()
                this.so.contract = "none"
                this.nocontract = "none"
            }

            this.so.customer = this.custkd.cust_kd
            this.so.address = this.custkd.alamat
            this.items = []
        },
        nocontract(){
            if(this.so.contract !== "none") {
                this.so.contract = this.nocontract.no_contract
                this.items = this.nocontract.items
            }
        },
    },
    created(){
        this.toast = useToast();
        
        this.authStore = useAuthStore()
        this.token = this.authStore.getAuthToken
        this.profile = this.authStore.getProfile
        this.fetchCustomers(this.token)
        
        this.salesStore = useSalesStore();
        if (this.salesStore.getSO.length > 0) {
            const findWork = this.salesStore.getSO.filter((data) => {
                return data.user === this.authStore.getProfile.userid
            })

            if(findWork[0].so != null) this.so = findWork[0].so
            if(findWork[0].so != null) this.soItems = findWork[0].so.items
            if(findWork[0].selectedCust != null) this.custkd = findWork[0].selectedCust
            if(findWork[0].selectedCust != null) this.fetchContracts(this.token, this.custkd.cust_kd, findWork[0].selectedContract)
        }
    },
    methods: {
        searching() {
            this.soItems = [];

            const searchTerm = "*" + this.searchItem + "*";
            const wildcardRegex = new RegExp(
                "^" + searchTerm.replace(/\*/g, ".*") + "$",
                "i"
            );
            
            const matchingObjects = this.so.items.filter((obj) =>
                Object.values(obj).some(
                (value) => typeof value === "string" && wildcardRegex.test(value)
                )
            );

            this.soItems = matchingObjects
        },
        creatingSO(){
            if(this.items.length <= 0) return;
            this.showCreate = true;
        },
        onClosedCreate(){
            this.showCreate = false;
        },
        captureData(value){
            const findItem = this.so.items.filter((data) => {
                return data.itemno === value.itemno
            })

            if(findItem.length > 0){
                const idx = this.so.items.indexOf(findItem[0]);
                this.so.items[idx].qty += value.qty
            } else {
                this.so.items.push(value)
            }

            this.soItems = this.so.items

            const storeData = {
                user: this.authStore.getProfile.userid,
                selectedCust: this.custkd,
                setSelectedContract: this.nocontract,
                so: this.so
            }
            
            this.salesStore.setSO(storeData);
            this.toast.add({ severity: 'success', summary: 'Success', detail: 'Items saved', group: 'crso', life: 3000 });
            this.showCreate = false;
        },
        async fetchCustomers(token) {
            try {
                const {data} = await axios.get(`/get-cust/${token}`)
                data.forEach((data, index) => {
                    data.image = `https://i.pravatar.cc/50?img=${index}.jpg`
                });

                this.customers = data
                this.loading = false;
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'crso', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout()
            }
        },
        async fetchAllProducts() {
            try {
                const {data} = await axios.get(`/get-mprice-jual/${this.token}`)
                this.items = data
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'crso', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout()
            }
        },
        async fetchContracts(token, custkd, savedWork) {
            try {
                const {data} = await axios.get(`/get-cont-by-cust/${custkd}/${token}`)

                this.contracts = data
                this.nocontract = savedWork
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'crso', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout()
            }
        },
        rowClass(data) {
            return data === this.selectedItem ? 'bg-rose-400' : 'bg-gray-100';
        },
        removeItems(item){
            let idx;
            this.so.items.map((data, index) => {
                if(data === item){
                    idx = index
                }
            })

            this.so.items.splice(idx, 1);
            this.soItems = this.so.items;
            this.salesStore.setSO(this.so);

            this.selectedItem = {
                itemno: null,
                itemdesc: null,
                category: null,
                disc_item: 0,
                disc_contract: 0,
                price: null,
                qty: null,
                unit: null,
                ppn: null,
                ppn_rp: null
            }

            const emptyOrder = {
                user: this.authStore.getProfile.userid,
                selectedCust: null,
                selectedContract: null,
                so: null
            }

            if (this.so.items.length <= 0) {
                this.salesStore.setSO(emptyOrder);
            }

            this.toast.add({ severity: 'success', summary: 'Success', detail: 'Items deleted', group: 'crso', life: 3000 });
        },
        confirmationDelete(event) {
            if(this.so.items.length <= 0) return;
            this.$confirm.require({
                target: event.currentTarget,
                group: 'popup',
                message: `Are you sure want to delete ${this.selectedItem.itemdesc} ?`,
                icon: 'pi pi-exclamation-circle',
                rejectProps: {
                    icon: 'pi pi-times',
                    label: 'Cancel',
                    outlined: true,
                    severity: 'secondary'
                },
                acceptProps: {
                    icon: 'pi pi-check',
                    label: 'Delete',
                    severity: 'danger'
                },
                accept: () => {
                    this.removeItems(this.selectedItem)
                },
                reject: () => {
                    this.toast.add({
                        severity: 'info',
                        summary: 'Canceled',
                        detail: 'Operation canceled',
                        group: 'crso',
                        life: 3000,
                    });
                },
            });
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        submitSO() {
            this.clearObject(this.error)

            if(!this.so.customer) {
                this.error.customer = true;

                setTimeout(() => {
                    this.error.customer = false;
                }, 5000)

                return;
            }
            
            if(this.so.contract == null) {
                this.error.contract = true;

                setTimeout(() => {
                    this.error.contract = false;
                }, 5000)

                return;
            }

            if(!this.so.pono) {
                this.error.pono = true;

                setTimeout(() => {
                    this.error.pono = false;
                }, 5000)

                return;
            }

            if(!this.so.po_date) {
                this.error.po_date = true;

                setTimeout(() => {
                    this.error.po_date = false;
                }, 5000)

                return;
            }

            if(!this.so.exp_date) {
                this.error.exp_date = true;

                setTimeout(() => {
                    this.error.exp_date = false;
                }, 5000)

                return;
            }

            if(this.so.items == []) {
                this.error.items = true;

                setTimeout(() => {
                    this.error.items = false;
                }, 5000)

                return;
            }

            this.$confirm.require({
                group: 'dialog',
                header: 'Confirmation',
                message: 'Are you sure want to edit this transaction ?',
                icon: 'pi pi-exclamation-triangle',
                rejectProps: {
                    label: 'Cancel',
                    icon: 'pi pi-times',
                    outlined: true,
                    size: 'small',
                    severity: 'secondary'
                },
                acceptProps: {
                    label: 'Submit',
                    icon: 'pi pi-check',
                    size: 'small',
                    severity: 'success'
                },
                accept: async() => {
                    await axios.post(`/post-so/${this.token}`, this.so)
                    this.toast.add({ severity: 'success', summary: 'Success', detail: 'Sales order created.', group: 'crso', life: 3000 });

                    const emptyOrder = {
                        user: this.authStore.getProfile.userid,
                        selectedCust: null,
                        selectedContract: null,
                        so: null
                    }

                    this.salesStore.setSO(emptyOrder);

                    setTimeout(() => {
                        this.$router.push({name: 'sales order'})
                    }, 1000)
                },
                reject: () => {
                    this.toast.add({ severity: 'info', summary: 'Canceled', detail: 'Operation canceled', group: 'crso', life: 3000 });
                }
            });
        }
    }
}
</script>

<style>
@import url('https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.min.css');

.form-radio {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex-shrink: 0;
  border-radius: 100%;
  border-width: 2px;
}

.form-radio:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

@media not print {
  .form-radio::-ms-check {
    border-width: 1px;
    color: transparent;
    background: inherit;
    border-color: inherit;
    border-radius: inherit;
  }
}

.form-radio:focus {
  outline: none;
}

.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  background-repeat: no-repeat;
  padding-top: 0.5rem;
  padding-right: 2.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
}

.form-select::-ms-expand {
  color: #a0aec0;
  border: none;
}

@media not print {
  .form-select::-ms-expand {
    display: none;
  }
}

@media print and (-ms-high-contrast: active), print and (-ms-high-contrast: none) {
  .form-select {
    padding-right: 0.75rem;
  }
}

.fade-in{
    animation: fades .3s ease-in-out;
}

@keyframes fades {
    0% {opacity: 0;}
    100%{opacity: 1;}
}
</style>