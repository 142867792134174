<template>
    <div class="w-full h-full bg-stone-100 pt-5">
        <div class="px-5">
            <div class="mb-2">
                <a @click="this.$router.go(-1)" class="focus:outline-none hover:underline text-gray-500 text-sm cursor-pointer"><i class="mdi mdi-arrow-left text-gray-400"></i>Back</a>
            </div>
            <div class="mb-2">
                <h1 v-if="!loading" class="text-3xl md:text-5xl font-bold text-gray-600">{{ upperCaseFirst(this.$route.name) }}.</h1>

                <div v-if="loading" class="mt-4 mb-1">
                    <Skeleton width="580px" height="40px"></Skeleton>
                </div>
            </div>
            <div class="mb-5 text-gray-400">
                <div v-if="!loading">
                    <router-link :to="{name: 'dashboard'}" class="focus:outline-none hover:underline text-gray-500">Home</router-link> / <router-link :to="{name: 'distribution order'}" class="focus:outline-none hover:underline text-gray-500">Distribution Order</router-link> / <router-link :to="{name: 'distribution order detail', params: {id: this.$route.params.id}}" class="focus:outline-none hover:underline text-gray-500">Detail</router-link>
                </div>

                <div v-if="loading" class="mt-4 mb-1">
                    <Skeleton width="180px" height="15px"></Skeleton>
                </div>
            </div>
        </div>
        
        <div class="relative w-full bg-white border-t border-gray-200 px-5 py-10 text-gray-800">
            <div class="relative w-full z-10">
                <div class="-mx-3 w-full grid xl:grid-cols-1 2xl:grid-cols-3 z-10 bg-transparent">
                    <div class="px-3 2xl:col-span-2 lg:pr-10">
                        <div class="relative flex justify-start item-start space-y-2 flex-col mb-8">
                            <h1 v-if="!loading" class="relative text-xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800 flex items-center">
                                Order #{{ distorder.no_do }}

                                <span v-if="distorder.f_confirm" class="absolute right-0 text-emerald-400 text-xl">Confirmed</span>
                            </h1>

                            <div v-if="loading" class="mt-[5px] mb-[5px]">
                                <Skeleton width="350px" height="30px"></Skeleton>
                            </div>

                            <div class="w-full h-12 flex justify-between">
                                <p v-if="!loading" class="text-md dark:text-gray-300 font-medium leading-6 text-gray-600 z-20">{{ formatDate(distorder.date_do) }}</p>

                                <div v-if="distorder.f_confirm" @click="fetchImageLogo" class="group relative h-full overflow-visible">
                                    <button
                                        class="bg-gray-200 w-8 h-8 flex justify-center items-center rounded-md text-stone-600 hover:text-white hover:bg-emerald-400 hover:translate-y-1 hover:duration-300"
                                    >
                                        <svg
                                        class="w-6 h-6"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        >
                                        <path
                                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                            stroke-linejoin="round"
                                            stroke-linecap="round"
                                        ></path>
                                        </svg>
                                    </button>

                                    <span
                                        class="absolute w-20 text-center -bottom-8 left-[50%] -translate-x-[50%] origin-bottom tracking-wider z-20 scale-0 px-1 rounded-lg bg-gray-100 text-gray-600 py-2 text-sm transition-all duration-300 ease-in-out group-hover:scale-100"
                                        >Download<span> </span
                                    ></span>
                                </div>

                            </div>

                            <div v-if="loading">
                                <Skeleton width="150px" height="15px"></Skeleton>
                            </div>
                        </div>

                        <div class="w-full mx-auto text-gray-800 font-light z-1">
                            <div v-if="!loading" class="relative w-full">
                                <DataTable class="-top-[40px] bg-transparent" :value="items" :rowClass="rowClass" removableSort sortMode="multiple" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50, 100]" tableStyle="min-width: 100%">
                                    <Column class="text-sm" headerClass="bg-transparent">
                                        <template #body="data">
                                            <div class="relative w-full flex items-center">
                                                <div class="overflow-hidden rounded-lg w-[100px] h-[100px] bg-gray-50 border border-gray-200">
                                                    <img src="../assets/images/no_image.jpg" alt="">
                                                </div>
                                                <div class="flex-grow pl-3">
                                                    <h6 class="text-xs uppercase text-gray-600">{{ data.data.itemno }}</h6>

                                                    <h6 class="font-semibold uppercase text-gray-600">{{ data.data.itemdesc }} - {{data.data.kategori_nm}}</h6>
                                                    <p class="text-gray-400">x {{ data.data.qty_do }}</p>

                                                    <div class="mt-2 flex items-end gap-3">
                                                        <span class="">SubTotal</span><span class="font-semibold text-teal-600 text-xl">Rp. {{ formatPrice((data.data.harga * data.data.qty_do) - ((data.data.harga * data.data.qty_do) * data.data.disc_akum / 100) + data.data.ppn_rp) }}</span>
                                                    </div>
                                                </div>

                                                <div class="flex flex-col items-end">
                                                    <div v-if="data.data.disc_akum > 0" class="flex items-center gap-3 ">
                                                        <span class="text-md px-2 bg-emerald-400 text-white rounded-md">Disc {{ data.data.disc_akum }}%</span> <span class="text-md text-gray-400 line-through">Rp. {{ formatPrice(data.data.harga * data.data.qty_do) }}</span>
                                                    </div>

                                                    <div>
                                                        <span class="font-semibold text-gray-600 text-lg">Rp. {{ formatPrice((data.data.harga * data.data.qty_do) - ((data.data.harga * data.data.qty_do) * data.data.disc_akum / 100)) }}</span>
                                                    </div>
                                                    
                                                    <div v-if="data.data.ppn_rp > 0">
                                                        <span class="text-gray-600 text-sm text-rose-400">VAT + Rp. {{ formatPrice(data.data.ppn_rp) }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </Column>
                                </DataTable>

                                <!-- 
                                <div class="relative w-full flex items-center border-b border-gray-200 pb-3 mb-3" v-for="item in items" :key="item.itemno">
                                    <div class="overflow-hidden rounded-lg w-[100px] h-[100px] bg-gray-50 border border-gray-200">
                                        <img src="../assets/images/no_image.jpg" alt="">
                                    </div>
                                    <div class="flex-grow pl-3">
                                        <h6 class="text-xs uppercase text-gray-600">{{ item.itemno }}</h6>

                                        <h6 class="font-semibold uppercase text-gray-600">{{ item.itemdesc }} - {{item.kategori_nm}}</h6>
                                        <p class="text-gray-400">x {{ item.qty_do }}</p>

                                        <div class="mt-2 flex items-end gap-3">
                                            <span class="">SubTotal</span><span class="font-semibold text-teal-600 text-xl">Rp. {{ formatPrice(item.subtotal + item.ppn_rp) }}</span>
                                        </div>
                                    </div>

                                    <div class="flex flex-col items-end">
                                        <div v-if="item.disc_akum > 0" class="flex items-center gap-3 ">
                                            <span class="text-md px-2 bg-emerald-400 text-white rounded-md">Disc {{ item.disc_akum }}%</span> <span class="text-md text-gray-400 line-through">Rp. {{ formatPrice(item.harga * item.qty_do) }}</span>
                                        </div>

                                        <div>
                                            <span class="font-semibold text-gray-600 text-lg">Rp. {{ formatPrice((item.harga * item.qty_do) - ((item.harga * item.qty_do) * item.disc_akum / 100)) }}</span>
                                        </div>
                                        
                                        <div v-if="item.ppn_rp > 0">
                                            <span class="text-gray-600 text-sm text-rose-400">VAT + Rp. {{ formatPrice(item.ppn_rp) }}</span>
                                        </div>
                                    </div>
                                </div>
                                -->
                            </div>

                            <div v-if="loading" class="relative w-full">
                                <div v-for="load in loadItem" :key="load" class="relative w-full flex items-center border-b border-gray-200 pb-3 mb-3 px-4">
                                    <div v-if="!loading" class="overflow-hidden rounded-lg w-[100px] h-[100px] bg-gray-50 border border-gray-200">
                                        <img src="../assets/images/no_image.jpg" alt="">
                                    </div>

                                    <div v-if="loading" class="relative w-[100px] h-[100px]">
                                        <Skeleton width="100%" height="100%"></Skeleton>
                                    </div>

                                    <div class="flex-grow pl-3">
                                        <div class="mb-2 mt-[9px]">
                                            <Skeleton width="110px" height="10px"></Skeleton>
                                        </div>

                                        <div class="mb-2 mt-[7px]">
                                            <Skeleton width="300px" height="15px"></Skeleton>
                                        </div>

                                        <div class="mb-[25px] mt-[10px]">
                                            <Skeleton width="50px" height="13px"></Skeleton>
                                        </div>

                                        <div>
                                            <Skeleton width="200px" height="15px"></Skeleton>
                                        </div>
                                    </div>

                                    <div class="flex flex-col items-end">
                                        <div class="mb-2">
                                            <Skeleton width="150px" height="12px"></Skeleton>
                                        </div>

                                        <div class="mb-2">
                                            <Skeleton width="180px" height="15px"></Skeleton>
                                        </div>
                                        
                                        <div>
                                            <Skeleton width="130px" height="10px"></Skeleton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mb-6 pb-6 border-b border-gray-200 text-gray-800">
                            <div class="w-full flex mb-3 items-center">
                                <div class="flex-grow">
                                    <span v-if="!loading" class="text-gray-600">Subtotal</span>

                                    <Skeleton v-if="loading" width="70px" height="15px"></Skeleton>
                                </div>
                                <div class="pl-3">
                                    <span v-if="!loading" class="font-semibold">Rp. {{ formatPrice(total_dpp) }}</span>

                                    <Skeleton v-if="loading" width="120px" height="15px"></Skeleton>
                                </div>
                            </div>
                            <div class="w-full flex items-center">
                                <div class="flex-grow">
                                    <span v-if="!loading" class="text-gray-600">Taxes</span>

                                    <Skeleton v-if="loading" class="mt-[13px] mb-2" width="60px" height="15px"></Skeleton>
                                </div>
                                <div class="pl-3">
                                    <span v-if="!loading" class="font-semibold">Rp. {{ formatPrice(vat) }}</span>

                                    <Skeleton v-if="loading" class="mt-[13px] mb-2" width="100px" height="15px"></Skeleton>
                                </div>
                            </div>
                        </div>
                        <div class="mb-6 pb-6 border-b border-gray-200 md:border-none text-gray-800 text-xl">
                            <div class="w-full flex items-center">
                                <div class="flex-grow">
                                    <span v-if="!loading" class="text-gray-600">Total</span>

                                    <Skeleton v-if="loading" class="mt-[3px] mb-2" width="60px" height="17px"></Skeleton>
                                </div>
                                <div class="pl-3">
                                    <span v-if="!loading" class="font-semibold text-gray-400 text-sm">IDR</span> <span v-if="!loading" class="font-semibold">Rp. {{ formatPrice(grand_total) }}</span>

                                    <Skeleton v-if="loading" class="mt-[3px] mb-2" width="150px" height="17px"></Skeleton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="px-3 w-full">
                        <div v-if="!loading" class="w-[100%] right-0 z-10 top-5 mx-auto mb-3">
                            <div class="relative flex items-center text-gray-400 focus-within:text-emerald-400">
                                <span class="absolute left-4 h-6 flex items-center pr-3 border-r border-gray-300">
                                    <svg xmls="http://ww50w3.org/2000/svg" class="w-4 fill-current" viewBox="0 0 35.997 36.004">
                                        <path id="Icon_awesome-search" data-name="search" d="M35.508,31.127l-7.01-7.01a1.686,1.686,0,0,0-1.2-.492H26.156a14.618,14.618,0,1,0-2.531,2.531V27.3a1.686,1.686,0,0,0,.492,1.2l7.01,7.01a1.681,1.681,0,0,0,2.384,0l1.99-1.99a1.7,1.7,0,0,0,.007-2.391Zm-20.883-7.5a9,9,0,1,1,9-9A8.995,8.995,0,0,1,14.625,23.625Z"></path>
                                    </svg>
                                </span>
                                
                                <input v-if="!loading" v-model="searchValue" @input="searching" type="search" name="search" id="search" placeholder="Search here" class="w-full pl-14 pr-4 py-2.5 rounded-lg text-sm text-gray-600 outline-none border border-gray-300 focus:border-emerald-400 transition">
                            </div>
                        </div>

                        <div v-if="loading" class="relative mb-3 mt-[1px]">
                            <Skeleton width="100%" height="44px"></Skeleton>
                        </div>

                        <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-3 text-gray-800 font-light mb-6">
                            <div class="w-full flex mb-3 items-center">
                                <div class="w-32">
                                    <span v-if="!loading" class="text-gray-600 font-semibold">Requestor</span>
                                    <div v-if="loading" class="mb-2 mt-2">
                                        <Skeleton width="80px" height="10px"></Skeleton>
                                    </div>
                                </div>
                                <div class="flex-grow pl-3">
                                    <span v-if="!loading">{{ upperCaseFirst(profile.userid) }}</span>
                                    <div v-if="loading" class="mb-2 mt-2">
                                        <Skeleton width="100px" height="10px"></Skeleton>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full flex items-center">
                                <div class="w-32">
                                    <span v-if="!loading" class="text-gray-600 font-semibold">Location</span>
                                    <div v-if="loading" class="mb-2 mt-1">
                                        <Skeleton width="110px" height="10px"></Skeleton>
                                    </div>
                                </div>
                                <div class="flex-grow pl-3">
                                    <span v-if="!loading">{{ upperCaseFirst(profile.department) }}, {{upperCaseFirst(profile.subdivision) }} - {{ upperCaseFirst(profile.division) }}</span>
                                    <div v-if="loading" class="mb-2 mt-1">
                                        <Skeleton width="80%" height="10px"></Skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 text-gray-800 font-light mb-6">
                            <div class="w-full px-4 py-2">
                                <h2 v-if="!loading" class="text-xl text-gray-400 font-bold mb-2">{{ distorder.cust_name }}</h2>

                                <div v-if="loading" class="mt-[4px]">
                                    <Skeleton width="350px" height="20px"></Skeleton>
                                </div>

                                <p v-if="!loading" class="text-gray-600 mb-4">CONTRACT: {{ distorder.no_contract }} - {{ distorder.contract_date }}</p>

                                <div v-if="loading" class="mt-[17px]">
                                    <Skeleton width="250px" height="15px"></Skeleton>
                                </div>
                                
                                <div v-if="!loading" class="flex flex-row items-end gap-3">
                                    <span class="text-gray-500">SO</span>
                                    <span class="text-xl font-bold mr-2">#{{ distorder.no_so }}</span>
                                </div>

                                <div v-if="loading" class="mt-[22px]">
                                    <Skeleton width="170px" height="20px"></Skeleton>
                                </div>
                                
                                <div v-if="!loading" class="flex items-center mb-6">
                                    <span class="text-gray-600">{{ formatDate(distorder.so_date) }}</span>
                                </div>

                                <div v-if="loading" class="mt-[10px]">
                                    <Skeleton width="150px" height="15px"></Skeleton>
                                </div>

                                <span v-if="!loading" class="text-gray-400 text-lg mt-2">Shipping Address</span>

                                <div v-if="loading" class="mt-[35px]">
                                    <Skeleton width="150px" height="15px"></Skeleton>
                                </div>

                                <p v-if="!loading" class="text-gray-700 mb-6">{{ upperCaseFirst(distorder.cust_alamat) }}</p>

                                <div v-if="loading" class="mt-[10px]">
                                    <Skeleton width="350px" height="15px"></Skeleton>
                                </div>

                                <div v-if="!loading" class="relative w-full flex flex-row gap-3 mb-6">
                                    <button v-if="!distorder.f_confirm" @click="confirmDO" class="block w-full mx-auto bg-teal-600 hover:bg-teal-800 focus:bg-teal-800 text-white rounded-md px-3 py-2 font-semibold"><i class="mdi mdi-lock-outline mr-1"></i> Confirm </button>
                                </div>

                                <div v-if="loading" class="mt-[30px] mb-6">
                                    <Skeleton width="100%" height="40px"></Skeleton>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ToastVue position="bottom-right" group="dtdo" />

        <ConfirmDialog group="dialog">
            <template #message="slotProps">
                <div class="flex flex-col items-center w-full gap-4 border-b border-surface-200 dark:border-surface-700 pb-2">
                    <i :class="slotProps.message.icon" class="!text-6xl text-yellow-400"></i>
                    <p>{{ slotProps.message.message }}</p>
                </div>
            </template>
        </ConfirmDialog>
    </div>
</template>

<script>
import Skeleton from 'primevue/skeleton';
import { useAuthStore } from '@/store/auth';
import { useToast } from 'primevue/usetoast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import axios from 'axios';

export default {
    name: 'DistributionOrderDetail',
    components: {
        Skeleton,
        DataTable,
        Column,
    },
    data(){
        return {
            companyName: null,
            companyAddress: null,
            loading: true,
            profile: null,
            token: null,
            authStore: null,
            loadItem: [1, 2, 3, 4, 5],
            distorder: {
                cust_name: null,
                cust_alamat: null,
                no_contract: null,
                contract_date: null,
                no_do: null,
                date_do: null,
                no_so: null,
                so_date: null,
                exp_date: null,
                f_status: null,
                f_confirm: false,
                items: []
            },
            items: [],
            toast: null,
            total_dpp: 0,
            vat: 0,
            grand_total: 0,
            searchValue: null,
            imageLogo: '/images/sti.png',
            ktnLogo: '/images/KTN.png',
            imageUrl: '/images/sti.png',
            imageBg: null,
            isDownload: false,
            fetchedLogo: null,
        }
    },
    mounted(){
        this.authStore = useAuthStore()
        this.token = this.authStore.getAuthToken
        this.profile = this.authStore.getProfile
        this.companyName = this.profile.subdivision
        if (this.companyName === 'SARI TIRTA INDONESIA') this.companyAddress = 'JL. Bridjen Katamso No.88, Jakarta Pusat'
        else this.companyAddress = ''
        this.toast = useToast()

        this.fetchDODetail()
    },
    methods: {
        searching(){
            this.items = [];
            const searchTerm = "*" + this.searchValue + "*";
            const wildcardRegex = new RegExp(
                "^" + searchTerm.replace(/\*/g, ".*") + "$",
                "i"
            );

            const matchingObjects = this.distorder.items.filter((obj) =>
                Object.values(obj).some(
                    (value) => typeof value === "string" && wildcardRegex.test(value)
                )
            );

            this.items = matchingObjects;
        },
        async fetchDODetail() {
            try {
                this.total_dpp = 0;
                this.vat = 0;
                this.grand_total = 0;

                const {data} = await axios.get(`/get-do-detail/${this.$route.params.id}/${this.token}`)

                this.distorder = data
                this.distorder.items.forEach(data => {
                    this.total_dpp += (data.harga * data.qty_do) - ((data.harga * data.qty_do) * data.disc_akum / 100)
                    this.vat += data.ppn_rp
                });

                this.grand_total = this.total_dpp + this.vat
                this.items = this.distorder.items

                console.log(this.distorder)
                this.loading = false;
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'dtdo', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout()
            }
        },
        async confirmDO() {
            try {
                this.$confirm.require({
                    group: 'dialog',
                    header: 'Confirmation',
                    message: 'Are you sure want to confirm this transaction ?',
                    icon: 'pi pi-exclamation-triangle',
                    rejectProps: {
                        label: 'Cancel',
                        icon: 'pi pi-times',
                        outlined: true,
                        size: 'small',
                        severity: 'secondary'
                    },
                    acceptProps: {
                        label: 'Submit',
                        icon: 'pi pi-check',
                        size: 'small',
                        severity: 'success'
                    },
                    accept: async() => {
                        const reqBody = {
                            no_do: this.$route.params.id
                        }

                        await axios.post(`/approve-do/${this.token}`, reqBody)
                        .then(() => {
                            this.toast.add({ severity: 'success', summary: 'Success', detail: 'DO Confirmed.', group: 'dtdo', life: 3000 })

                            setTimeout(() => {
                                window.location.reload()
                            }, 1500)
                        })
                        .catch((error) =>{
                            this.toast.add({ severity: 'error', summary: 'Success', detail: error, group: 'dtdo', life: 3000 })
                        })
                    },
                    reject: () => {
                        this.toast.add({ severity: 'info', summary: 'Canceled', detail: 'Operation canceled', group: 'dtso', life: 3000 });
                    }
                });
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'dtdo', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout()
            }
        },
        async fetchImageAsBase64(url) {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
        async fetchImageLogo(){
            try {
                this.isDownload = true
                let imagename;

                console.log(this.profile.subdivision)
                if (this.profile.subdivision === "SARI TIRTA INDONESIA") imagename = this.imageLogo
                else imagename = this.ktnLogo
                await fetch(imagename).then(res => {
                    return res.blob()
                }).then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        this.fetchedLogo = reader.result
                        this.downloadDO()
                        this.downloadINV()
                    };

                    reader.readAsDataURL(blob);
                })
            } catch (error) {
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'dtdo', life: 3000 });
            }
        },
        async downloadDO(){
            try {
                const barang = this.distorder.items

                let rowPDF = [
                [
                    { text: 'No', bold: true, alignment: 'center', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                    { text: 'Item Code', bold: false, alignment: 'left', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                    { text: 'Description', bold: false, alignment: 'left', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                    { text: 'Unit', bold: false, alignment: 'right', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                    { text: 'Price', bold: false, alignment: 'right', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                    { text: 'Quantity', bold: false, alignment: 'right', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                    { text: 'Disc', bold: false, alignment: 'right', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                    { text: 'Amount', bold: false, alignment: 'right', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                ],
                ];

                let total = 0;
                let vat = 0;
                let ppn = 0;
                barang.forEach(async(data, index) => {
                if(data.nmbar !== ""){
                    const arrBarang = [
                        { text: index + 1 ?? "-", bold: true, alignment: 'center', fontSize: 9},
                        { text: data.itemno ?? "-", bold: false, alignment: 'left', fontSize: 8},
                        { text: data.itemdesc ?? "-", bold: false, alignment: 'left', fontSize: 8},
                        { text: data.kdstn ?? "-", bold: false, alignment: 'right', fontSize: 9},
                        { text: this.formatPrice(data.harga) ?? 0, bold: false, alignment: 'right', fontSize: 9},
                        { text: data.qty_do ?? 0, bold: false, alignment: 'right', fontSize: 9},
                        { text: this.formatPrice(data.disc_akum) ?? 0, bold: false, alignment: 'right', fontSize: 9},
                        { text: this.formatPrice((data.harga * data.qty_do) - ((data.harga * data.disc_akum / 100) * data.qty_do)), bold: false, alignment: 'right', fontSize: 9, margin: [0, 0, 5, 0]},
                    ]

                    rowPDF.push(arrBarang)
                    total += (data.harga * data.qty_do) - ((data.harga * data.disc_akum / 100) * data.qty_do);
                    vat += ((data.harga * data.ppn) / 100) * data.qty_do
                    ppn = data.ppn
                }
                });

                const sumTotal = [
                    { text: "Total", bold: false, alignment: 'left', fontSize: 10, colSpan: 7 },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {text: `${this.formatPrice(total)}`, bold: true, alignment: 'right', fontSize: 9, margin: [0, 0, 5, 0]},
                ]
                rowPDF.push(sumTotal)

                const vatTotal = [
                    { text: `VAT ${ppn}%`, bold: false, alignment: 'left', fontSize: 10, colSpan: 7 },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {text: `${this.formatPrice(vat)}`, bold: true, alignment: 'right', fontSize: 9, color: 'red', margin: [0, 0, 5, 0]},
                ]
                rowPDF.push(vatTotal)

                const grandTotal = [
                    { text: `Grand Total`, bold: false, alignment: 'left', fontSize: 10, colSpan: 7 },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {text: `${this.formatPrice(total + vat)}`, bold: true, alignment: 'right', fontSize: 9, color: 'green', margin: [0, 0, 5, 0]},
                ]
                rowPDF.push(grandTotal)

                let pdfContent = [];
                pdfContent.push(
                {
                    columns: [
                    {
                        margin: [0, 10, 0, 30],
                        width: '40%',
                        text: ''
                    },
                    {
                        margin: [52, 10, 0, 30],
                        width: '60%',
                        table: { 
                        headerRows: 1,
                        widths: [80, 50, 50, 40],
                        height: 80,              
                        body: [
                            [
                                { text: 'PO NO', alignment: 'left', fontSize: 10,},
                                { text: this.distorder.no_po, alignment: 'left', fontSize: 12, colSpan: 3},
                                {},
                                {},
                            ],
                            [
                                { text: 'PO Date', alignment: 'left', fontSize: 10},
                                { text: `${this.formatDate(this.distorder.po_date)}`, alignment: 'left', colSpan: 3, fontSize: 10},
                                {},
                                {}
                            ],
                            [
                                { text: 'DO NO', alignment: 'left', fontSize: 10},
                                { text: this.distorder.no_do, alignment: 'left', fontSize: 12, colSpan: 3},
                                {},
                                {},
                            ],
                            [
                                { text: 'DO Date', alignment: 'left', fontSize: 10},
                                { text: `${this.formatDate(this.distorder.date_do)}`, alignment: 'left', colSpan: 3, fontSize: 10},
                                {},
                                {}
                            ],
                            [
                                { text: 'CustID', alignment: 'left', fontSize: 10},
                                { text: this.distorder.cust_kd, alignment: 'left', colSpan: 3, fontSize: 10},
                                {},
                                {}
                            ],
                            [
                                { text: 'Cust Name', alignment: 'left', fontSize: 10},
                                { text: this.distorder.cust_name, alignment: 'left', colSpan: 3, fontSize: 10},
                                {},
                                {}
                            ],
                        ],
                        },
                    }
                    ]
                },
                {
                    canvas: [
                    {
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 520,
                        y2: 0,
                        lineWidth: 0.5
                    }
                    ],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                },
                {
                    columns: [
                    { 
                        layout: 'noBorders',
                        margin: [0, 0, 0, 0],
                        width: '50%',
                        table: { 
                        headerRows: 1,
                        widths: ['30%', '3%', '67%'],
                        body: [
                            [
                            {
                                text: 'Vendor',
                                bold: true, 
                                alignment: 'left', 
                                fontSize: 14,
                                colSpan: 3,
                            },
                            {},
                            {}
                            ],
                            [
                            { text: this.companyName, bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0], colSpan: 3},
                            {},
                            {},
                            ],
                            [
                            { text: this.companyAddress, bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0], colSpan: 3},
                            {},
                            {},
                            ],
                            [
                            { text: 'Jakarta Pusat', bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0], colSpan: 3},
                            {},
                            {},
                            ],
                            [
                            { text: '021-21253888', bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0], colSpan: 3},
                            {},
                            {},
                            ],
                            [
                            { text: '021-21252153', bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0], colSpan: 3},
                            {},
                            {},
                            ],
                        ]
                        }
                    },
                    { 
                        layout: 'noBorders',
                        margin: [0, 0, 0, 0],
                        width: '50%',
                        table: { 
                        headerRows: 1,
                        widths: ['30%', '3%', '67%'],
                        body: [
                            [
                            {
                                text: 'Ship To',
                                bold: true, 
                                alignment: 'left', 
                                fontSize: 14,
                                colSpan: 3,
                            },
                            {},
                            {}
                            ],
                            [
                            { text: this.distorder.cust_name, bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0], colSpan: 3},
                            {},
                            {},
                            ],
                            [
                            { text: this.distorder.alamat, bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0], colSpan: 3},
                            {},
                            {},
                            ],
                            [
                            { text: "", bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0], colSpan: 3},
                            {},
                            {},
                            ],
                            [
                            { text: "", bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0], colspan: 3},
                            {},
                            {},
                            ],
                        ]
                        }
                    }
                    ],
                    margin: [0, 20, 0, 0],
                },
                {
                    canvas: [
                    {
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 520,
                        y2: 0,
                        lineWidth: 0.5
                    }
                    ],
                    margin: [0, 25, 0, 0],
                    alignment: 'center'
                },
                {
                    text: 'Items',
                    bold: true, 
                    alignment: 'left', 
                    fontSize: 12,
                    margin: [0, 20, 0, 10],
                },
                { 
                    layout: 'lightHorizontalLines',
                    table: { 
                    headerRows: 1,
                    widths: ['5%', '20%', '23%', '9%', '11%', '10%', '7%', '15%'],  
                    heights: function (rowIndex) {
                        if (rowIndex === 0) {
                            return 20;
                        } else {
                            return 15;
                        }
                    },
                    body: rowPDF
                    },
                },
                { 
                    margin: [0, 40, 0, 0],
                    columns: [
                        {
                            margin: [0, 0, 0, 30],
                            width: '40%',
                            text: ''
                        },
                        {
                            margin: [0, 0, 0, 30],
                            layout: {
                                hLineWidth: () => 0,
                                vLineWidth: () => 0,
                            },
                            alignment: 'left',
                            width: '60%',
                            table: { 
                            headerRows: 1,
                            widths: ['60%', '40%'],
                            height: 100,              
                            body: [
                                    [
                                        { text: 'Delivered By', alignment: 'left', bold: true, decoration: 'underline', fontSize: 11,},
                                        { text: 'Cost Control', alignment: 'left', bold: true, decoration: 'underline', fontSize: 11,},
                                    ],
                                    [
                                        { text: 'Name: ', alignment: 'left', bold: false, fontSize: 9,},
                                        { text: 'Name: ', alignment: 'left', bold: false, fontSize: 9,},
                                    ],
                                    [
                                        { text: 'Date: ', alignment: 'left', bold: false, fontSize: 9,},
                                        { text: 'Date: ', alignment: 'left', bold: false, fontSize: 9,},
                                    ],
                                    [
                                        { text: 'Signature', fontSize: 9, alignment: 'left', margin: [0, 70, 0, 0], lineHeight: 1},
                                        { text: 'Signature & Stamp', fontSize: 9, alignment: 'left', margin: [0, 70, 0, 0], lineHeight: 1},
                                    ]                
                                ]
                            },
                        }
                    ]
                },
                )
                
                const pdfBuffer = await this.creatingPDF(pdfContent);
                const blob = new Blob([pdfBuffer], { type: 'application/pdf' });
                // this.pdfPO = new File([blob], `PO-${this.selectedPO.po_no}.pdf`, { type: 'application/pdf' });
                this.pdfPO = blob;
                this.pdfName = `DO-${this.distorder.no_do}.pdf`;
                
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');

                a.href = url;
                a.download = `DO-${this.distorder.no_do}.pdf`;
                a.style = 'opacity: 0';
                document.body.appendChild(a);

                a.click();
                document.body.removeChild(a);

                this.isDownload = false;
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'dtdo', life: 3000 });
            }
        },
        async downloadINV(){
            try {
                const barang = this.distorder.items

                let rowPDF = [
                [
                    { text: 'No', bold: true, alignment: 'center', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                    { text: 'Item Code', bold: false, alignment: 'left', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                    { text: 'Description', bold: false, alignment: 'left', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                    { text: 'Unit', bold: false, alignment: 'right', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                    { text: 'Price', bold: false, alignment: 'right', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                    { text: 'Quantity', bold: false, alignment: 'right', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                    { text: 'Disc', bold: false, alignment: 'right', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                    { text: 'Amount', bold: false, alignment: 'right', fontSize: 9, fillColor: '#E0DAD3', margin: [0, 5, 0, 0]},
                ],
                ];

                let total = 0;
                let vat = 0;
                let ppn = 0;
                barang.forEach(async(data, index) => {
                if(data.nmbar !== ""){
                    const arrBarang = [
                        { text: index + 1 ?? "-", bold: true, alignment: 'center', fontSize: 9},
                        { text: data.itemno ?? "-", bold: false, alignment: 'left', fontSize: 8},
                        { text: data.itemdesc ?? "-", bold: false, alignment: 'left', fontSize: 8},
                        { text: data.kdstn ?? "-", bold: false, alignment: 'right', fontSize: 9},
                        { text: this.formatPrice(data.harga) ?? 0, bold: false, alignment: 'right', fontSize: 9},
                        { text: data.qty_do ?? 0, bold: false, alignment: 'right', fontSize: 9},
                        { text: this.formatPrice(data.disc_akum) ?? 0, bold: false, alignment: 'right', fontSize: 9},
                        { text: this.formatPrice((data.harga * data.qty_do) - ((data.harga * data.disc_akum / 100) * data.qty_do)), bold: false, alignment: 'right', fontSize: 9, margin: [0, 0, 5, 0]},
                    ]

                    rowPDF.push(arrBarang)
                    total += (data.harga * data.qty_do) - ((data.harga * data.disc_akum / 100) * data.qty_do);
                    vat += ((data.harga * data.ppn) / 100) * data.qty_do
                    ppn = data.ppn
                }
                });

                const sumTotal = [
                    { text: "Total", bold: false, alignment: 'left', fontSize: 10, colSpan: 7 },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {text: `${this.formatPrice(total)}`, bold: true, alignment: 'right', fontSize: 9, margin: [0, 0, 5, 0]},
                ]
                rowPDF.push(sumTotal)

                const vatTotal = [
                    { text: `VAT ${ppn}%`, bold: false, alignment: 'left', fontSize: 10, colSpan: 7 },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {text: `${this.formatPrice(vat)}`, bold: true, alignment: 'right', fontSize: 9, color: 'red', margin: [0, 0, 5, 0]},
                ]
                rowPDF.push(vatTotal)

                const grandTotal = [
                    { text: `Grand Total`, bold: false, alignment: 'left', fontSize: 10, colSpan: 7 },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {text: `${this.formatPrice(total + vat)}`, bold: true, alignment: 'right', fontSize: 9, color: 'green', margin: [0, 0, 5, 0]},
                ]
                rowPDF.push(grandTotal)

                let pdfContent = [];
                pdfContent.push(
                {
                    columns: [
                    { 
                        layout: 'noBorders',
                        margin: [0, 0, 0, 0],
                        width: '40%',
                        table: { 
                        headerRows: 1,
                        widths: ['30%', '3%', '67%'],
                        body: [
                            [
                            { text: this.companyName, bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0], colSpan: 3},
                            {},
                            {},
                            ],
                            [
                            { text: this.companyAddress, bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0], colSpan: 3},
                            {},
                            {},
                            ],
                            [
                            { text: 'Jakarta Pusat', bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0], colSpan: 3},
                            {},
                            {},
                            ],
                            [
                            { text: '021-21253888', bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0], colSpan: 3},
                            {},
                            {},
                            ],
                            [
                            { text: '021-21252153', bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0], colSpan: 3},
                            {},
                            {},
                            ],
                        ]
                        }
                    },
                    {
                        margin: [52, 10, 0, 20],
                        width: '60%',
                        table: { 
                        headerRows: 1,
                        widths: [80, 50, 50, 40],
                        height: 80,              
                        body: [
                            [
                                { text: 'Invoice NO', alignment: 'left', fontSize: 10,},
                                { text: this.distorder.invoice_no, alignment: 'left', bold: true, fontSize: 10, colSpan: 3},
                                {},
                                {},
                            ],
                            [
                                { text: 'Invoice Date', alignment: 'left', fontSize: 10},
                                { text: `${this.formatDate(this.distorder.invoice_date)}`, bold: true, alignment: 'left', colSpan: 3, fontSize: 10},
                                {},
                                {}
                            ],
                            [
                                { text: 'Due Date', alignment: 'left', fontSize: 10,},
                                { text: this.distorder.due_date, alignment: 'left', bold: true, fontSize: 10, colSpan: 3},
                                {},
                                {},
                            ],
                        ],
                        },
                    }
                    ]
                },
                {
                    canvas: [
                    {
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 520,
                        y2: 0,
                        lineWidth: 0.5
                    }
                    ],
                    margin: [0, 15, 0, 0],
                    alignment: 'center'
                },
                {
                    columns: [
                    { 
                        layout: 'noBorders',
                        margin: [0, 0, 0, 0],
                        width: '45%',
                        table: { 
                        headerRows: 1,
                        widths: ['25%', '3%', '72%'],
                        body: [
                            [
                                { text: 'CustID', bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                { text: ':', bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                { text: this.distorder.cust_kd, bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                            ],
                            [
                                { text: 'Cust Name', bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                { text: ':', bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                { text: this.distorder.cust_name, bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                            ],
                            [
                                { text: 'Address', bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                { text: ':', bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                {text: this.distorder.cust_alamat, bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                            ],
                        ]
                        }
                    },
                    {
                        width: '10%',
                        text: ''
                    },
                    { 
                        layout: 'noBorders',
                        margin: [0, 0, 0, 0],
                        width: '45%',
                        table: { 
                        headerRows: 1,
                        widths: ['20%', '3%', '77%'],
                        body: [
                            [
                                { text: 'PO NO', bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                { text: ':', bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                { text: this.distorder.no_po, bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                            ],
                            [
                                { text: 'PO Date', bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                { text: ':', bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                { text: this.distorder.po_date, bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                            ],
                            [
                                { text: 'DO NO', bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                { text: ':', bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                { text: this.distorder.no_do, bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                            ],
                            [
                                { text: 'DO Date', bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                { text: ':', bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                { text: this.distorder.date_do, bold: true, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                            ],
                            [
                                { text: 'Shipping Address', bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                { text: ':', bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                                {text: this.distorder.cust_alamat, bold: false, alignment: 'left', fontSize: 10, lineHeight: 1, margin: [0, 1, 0, 0]},
                            ],
                        ]
                        }
                    }
                    ],
                    margin: [0, 20, 0, 0],
                },
                {
                    canvas: [
                    {
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 520,
                        y2: 0,
                        lineWidth: 0.5
                    }
                    ],
                    margin: [0, 25, 0, 0],
                    alignment: 'center'
                },
                {
                    text: 'Items',
                    bold: true, 
                    alignment: 'left', 
                    fontSize: 12,
                    margin: [0, 20, 0, 10],
                },
                { 
                    layout: 'lightHorizontalLines',
                    table: { 
                    headerRows: 1,
                    widths: ['5%', '20%', '23%', '9%', '11%', '10%', '7%', '15%'],  
                    heights: function (rowIndex) {
                        if (rowIndex === 0) {
                            return 20;
                        } else {
                            return 15;
                        }
                    },
                    body: rowPDF
                    },
                },
                { 
                    margin: [0, 40, 0, 0],
                    columns: [
                        {
                            margin: [0, 0, 0, 30],
                            alignment: 'left',
                            width: '60%',
                            table: { 
                            headerRows: 1,
                            widths: ['25%', '5%', '70%'],
                            height: 100,              
                            body: [
                                    [
                                        { text: 'Payment Have To Be Transfer To : ', fontSize: 9, alignment: 'left', margin: [0, 0, 0, 0], colSpan: 3},
                                        {},
                                        {},
                                    ],
                                    [
                                        { text: 'Virtual Account No Bank Central Asia (BCA) : ', fontSize: 9, alignment: 'left', margin: [0, 0, 0, 0], colSpan: 3},
                                        {},
                                        {},
                                    ],
                                    [
                                        { text: 'Account Number', fontSize: 9, alignment: 'left', margin: [0, 0, 0, 0]},
                                        { text: ':', fontSize: 9, alignment: 'left', margin: [0, 0, 0, 0]},
                                        { text: '082592205240001', fontSize: 9, alignment: 'left', margin: [0, 0, 0, 0]},
                                    ],
                                    [
                                        { text: 'Account Name', fontSize: 9, alignment: 'left', margin: [0, 0, 0, 0]},
                                        { text: ':', fontSize: 9, alignment: 'left', margin: [0, 0, 0, 0]},
                                        { text: '0', fontSize: 9, alignment: 'left', margin: [0, 0, 0, 0]},
                                    ],
                                ]
                            },
                            layout: {
                                hLineWidth: (i, node) => (i === 0 || i === node.table.body.length ? 1 : 0), 
                                vLineWidth: (i, node) => (i === 0 || i === node.table.widths.length ? 1 : 0),
                                hLineColor: () => 'black',
                                vLineColor: () => 'black'
                            },
                        },
                        {
                            width: '10%',
                            text: '',
                        },
                        {
                            margin: [0, 0, 0, 30],
                            layout: {
                                hLineWidth: () => 0,
                                vLineWidth: () => 0,
                            },
                            alignment: 'left',
                            width: '30%',
                            table: { 
                            headerRows: 1,
                            widths: ['100%'],
                            height: 100,              
                            body: [
                                    [
                                        { text: 'Finance', fontSize: 9, alignment: 'center', margin: [0, 60, 0, 0], decoration: 'underline', lineHeight: 1},
                                    ],
                                    [
                                        { text: 'Signature & Stamp', fontSize: 9, alignment: 'center', margin: [0, 0, 0, 0], lineHeight: 1},
                                    ]
                                ]
                            },
                        }
                    ]
                },
                )
                
                const pdfBuffer = await this.creatingINV(pdfContent);
                const blob = new Blob([pdfBuffer], { type: 'application/pdf' });
                // this.pdfPO = new File([blob], `PO-${this.selectedPO.po_no}.pdf`, { type: 'application/pdf' });
                this.pdfPO = blob;
                this.pdfName = `Invoice-${this.distorder.no_do}.pdf`;
                
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');

                a.href = url;
                a.download = `Invoice-${this.distorder.no_do}.pdf`;
                a.style = 'opacity: 0';
                document.body.appendChild(a);

                a.click();
                document.body.removeChild(a);

                this.isDownload = false;
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'dtdo', life: 3000 });
            }
        },
        creatingPDF(pdfContent){
            return new Promise((resolve) => {
                pdfMake.createPdf({ 
                pageSize: 'A4',
                pageOrientation: 'potrait',
                pageMargins: [ 40, 60, 40, 40 ],
                footer: function(currentPage, pageCount) { 
                    return [
                    { text: `Page ${currentPage.toString()} of ${pageCount}`, alignment: 'center' },
                    ]
                },
                header: () => {
                    return [
                    {
                        columns: [
                        { 
                        layout: 'noBorders',
                        margin: [40, 20, 0, 0],
                        table: { 
                            headerRows: 1,
                            widths: ['100%'],   
                            body: [
                            [
                                {
                                    text: this.companyName,
                                    bold: true, 
                                    alignment: 'left', 
                                    fontSize: 14,
                                    lineHeight: 0.7,
                                    margin: [0, 0, 0, 0]
                                }
                            ],
                            [
                                {
                                    text: this.companyAddress,
                                    bold: false, 
                                    alignment: 'left', 
                                    fontSize: 10,
                                    lineHeight: 0.7,
                                    margin: [0, 0, 0, 5]
                                }
                            ],
                            ]
                        }
                        },
                        {
                        image: this.fetchedLogo,
                        fit: [120, 120],
                        alignment: 'right',
                        margin: [0, 10, 20, 0]
                        }
                    ]
                    },
                    {
                    canvas: [
                        {
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 520,
                        y2: 0,
                        lineWidth: 1
                        }
                    ],
                    margin: [0, 0, 0, 0],
                    alignment: 'center'
                    }
                ]
                },
                content: pdfContent 
                }).getBuffer((buffer) => {
                resolve(buffer);
                });
            });
        },
        creatingINV(pdfContent){
            return new Promise((resolve) => {
                pdfMake.createPdf({ 
                pageSize: 'A4',
                pageOrientation: 'potrait',
                pageMargins: [ 40, 60, 40, 40 ],
                footer: function(currentPage, pageCount) { 
                    return [
                    { text: `Page ${currentPage.toString()} of ${pageCount}`, alignment: 'center' },
                    ]
                },
                header: () => {
                    return [
                    {
                        columns: [
                        { 
                        layout: 'noBorders',
                        margin: [40, 20, 0, 0],
                        table: { 
                            headerRows: 1,
                            widths: ['100%'],   
                            body: [
                                [
                                    {
                                        text: 'INVOICE',
                                        bold: true, 
                                        alignment: 'left', 
                                        fontSize: 20,
                                        lineHeight: 0.7,
                                        margin: [0, 0, 0, 6]
                                    }
                                ]
                            ]
                        }
                        },
                        {
                        image: this.fetchedLogo,
                        fit: [120, 120],
                        alignment: 'right',
                        margin: [0, 10, 20, 0]
                        }
                    ]
                    },
                ]
                },
                content: pdfContent 
                }).getBuffer((buffer) => {
                resolve(buffer);
                });
            });
        },
    }   
}
</script>