<template>
    <div v-if="this.$route.name !== 'login' && this.$route.name !== 'not found' && this.$route.name !== 'error'" class="relative sliding-in">
        <aside class="ml-[-100%] fixed z-10 top-0 pb-3 px-6 w-full flex flex-col justify-between h-screen border-r bg-white transition duration-300 sm:w-4/12 lg:ml-0 lg:w-[25%] xl:w-[20%] 2xl:w-[15%] overflow-y-auto
                    [&::-webkit-scrollbar]:w-2
                    [&::-webkit-scrollbar-track]:rounded-full
                    [&::-webkit-scrollbar-track]:bg-gray-100
                    [&::-webkit-scrollbar-thumb]:rounded-full
                    [&::-webkit-scrollbar-thumb]:bg-stone-400
                    dark:[&::-webkit-scrollbar-track]:bg-neutral-700
                    dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
            <div>
                <div class="-mx-6 px-6 py-4">
                    <a href="#" title="home">
                        <img v-if="profile.subdivision === 'SARI TIRTA INDONESIA'" src="/images/sti.png" class="w-full" alt="logo">
                        <img v-else src="/images/KTN.png" class="w-full" alt="logo">
                    </a>
                </div>

                <div class="mt-8 text-center">
                    <img src="../assets/images/default.png" alt="" class="w-28 h-28 m-auto rounded-full object-cover lg:w-28 lg:h-28">
                    <h5 class="mt-4 text-xl font-semibold text-gray-600 block">{{ upperCaseFirst(profile.userid) }}</h5>
                    <span class="text-gray-400 block">{{ upperCaseFirst(profile.department) }}</span>
                    <span class="text-gray-400 block">{{ upperCaseFirst(profile.subdivision) }} - {{ upperCaseFirst(profile.division) }}</span>
                </div>

                <ul class="space-y-2 tracking-wide mt-8">
                    <li v-for="nav in menu" :key="nav.id">
                        <router-link :to="{name: nav.link}" aria-label="dashboard" class="relative px-4 py-3 flex items-center space-x-4 rounded-xl text-gray-600 group font-light"
                            :class="{'bg-stone-800 hover:bg-stone-800 text-white': nav.active.includes(this.$route.name), 'hover:bg-stone-100 hover:text-amber-900': !nav.active.includes(this.$route.name) }">
                            <i class="large material-icons">{{ nav.icon }}</i>
                            <span class="-mr-1 font-medium">{{ nav.name }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="px-6 -mx-6 pt-4 flex justify-between items-center border-t">
                <button class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group" @click="logout">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                    <span class="group-hover:text-gray-700">Logout</span>
                </button>
            </div>
        </aside>

        <aside v-if="isMobile" class="lg:hidden fixed z-10 top-[50px] pb-3 px-6 pt-6 w-full h-full flex flex-col justify-between h-screen border-r bg-white transition duration-300">
            <div>
                <div class="mt-2 text-center">
                    <img src="../assets/images/default.png" alt="" class="w-28 h-28 m-auto rounded-full object-cover lg:w-28 lg:h-28">
                    <h5 class="mt-4 text-xl font-semibold text-gray-600 block">{{ upperCaseFirst(profile.userid) }}</h5>
                    <span class="text-gray-400 block">{{ upperCaseFirst(profile.department) }}</span>
                </div>

                <ul class="space-y-2 tracking-wide mt-8">
                    <li v-for="nav in menu" :key="nav.id">
                        <router-link :to="{name: nav.link}" aria-label="dashboard" class="relative px-4 py-3 flex items-center space-x-4 rounded-xl text-gray-600 group font-light"
                            :class="{'bg-stone-800 hover:bg-stone-800 text-white': nav.active.includes(this.$route.name), 'hover:bg-stone-100 hover:text-amber-900': !nav.active.includes(this.$route.name) }">
                            <i class="large material-icons">{{ nav.icon }}</i>
                            <span class="-mr-1 font-medium">{{ nav.name }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="absolute bottom-[70px] w-full z-10 px-6 -mx-6 pt-4 flex justify-between items-center border-t">
                <button class="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group" @click="logout">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                    <span class="group-hover:text-gray-700">Logout</span>
                </button>
            </div>
        </aside>
        
        <div class="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
            <div class="sticky z-10 top-0 h-16 border-b bg-white drop-shadow-sm lg:py-2.5">
                <div class="px-6 flex items-center justify-between space-x-4 2xl:container">
                    <h5 hidden class="text-2xl text-gray-600 font-medium lg:block"></h5>
                    <button @click="showMenu" class="w-12 h-16 -mr-2 border-r z-20 lg:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                    <div class="flex space-x-4">
                        <button aria-label="search" class="w-10 h-10 rounded-xl border bg-gray-100 focus:bg-gray-100 active:bg-gray-200 md:hidden">
                            <svg xmls="http://ww50w3.org/2000/svg" class="w-4 mx-auto fill-current text-gray-600" viewBox="0 0 35.997 36.004">
                                <path id="Icon_awesome-search" data-name="search" d="M35.508,31.127l-7.01-7.01a1.686,1.686,0,0,0-1.2-.492H26.156a14.618,14.618,0,1,0-2.531,2.531V27.3a1.686,1.686,0,0,0,.492,1.2l7.01,7.01a1.681,1.681,0,0,0,2.384,0l1.99-1.99a1.7,1.7,0,0,0,.007-2.391Zm-20.883-7.5a9,9,0,1,1,9-9A8.995,8.995,0,0,1,14.625,23.625Z"></path>
                            </svg>
                        </button>

                        <button aria-label="chat" class="w-10 h-10 rounded-xl border bg-gray-100 hover:bg-stone-900 focus:bg-stone-900 active:bg-stone-900 group">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 m-auto text-gray-600 group-hover:text-white group-focus:text-white group-active:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                            </svg>
                        </button>

                        <button aria-label="notification" class="w-10 h-10 rounded-xl border bg-gray-100 hover:bg-stone-900 focus:bg-stone-900 active:bg-stone-900 group">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 m-auto text-gray-600 group-hover:text-white group-focus:text-white group-active:text-white" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div class="px-0 pt-0 w-full">
                <div class="grid grid-cols-1" ref="appContainer">
                    <transition
                        mode="out-in"
                        v-on:before-enter="routeTransitionBeforeEnter"
                        v-on:enter="routeTransitionEnter"
                        v-on:after-enter="routeTransitionAfterEnter"
                        v-on:leave="routeTransitionLeave"
                    >
                        <router-view />
                    </transition>
                </div>
            </div>
        </div>
    </div>

    <div v-if="this.$route.name == 'login' || this.$route.name == 'not found' || this.$route.name == 'error'" >
        <router-view />
    </div>
</template>

<script>
import {gsap} from 'gsap';
import { useAuthStore } from '@/store/auth';

export default {
    name: 'LayoutVue',
    data(){
        return {
            menu: [
                {id: 1, name: 'Dashboard', link: 'dashboard', icon: 'dashboard', permission: 'dashboard', active: ['dashboard']},
                {id: 1, name: 'Sales Order', link: 'sales order', icon: 'attach_money', permission: 'dashboard', active: ['sales order', 'create sales order', 'sales order detail']},
                {id: 1, name: 'Distribution Order', link: 'distribution order', icon: 'shopping_basket', permission: 'dashboard', active: ['distribution order', 'create distribution order', 'distribution order detail']},
                {id: 1, name: 'Profile', link: 'profile', icon: 'account_circle', permission: 'dashboard', active: ['profile']}
            ],
            isMobile: true,
            authStore: null,
            profile: null,
            routeTransitionAfterEnter: null,
        }
    },
    created(){
        this.authStore = useAuthStore()
        this.profile = this.authStore.getProfile
    },
    methods: {
        logout(){
            this.userLogout()
        },
        routeTransitionBeforeEnter(el) {
        gsap.set(el, { xPercent: -10, autoAlpha: 0 });
        },
        routeTransitionEnter(el, done) {
        gsap.to(el, {
            duration: 0.6,
            xPercent: 0,
            autoAlpha: 1,
            onComplete: () => {
            done();
            this.$refs.appContainer.style.overflow = "auto";
            },
        });
        },
        routeTransitionLeave(el, done) {
            this.$refs.appContainer.style.overflow = "hidden";
            gsap.to(el, {
                duration: 0.3,
                xPercent: 10,
                autoAlpha: 0,
                onComplete: done,
            });
        },
        showMenu(){
            if(this.isMobile) this.isMobile = false;
            else this.isMobile = true;
        }
    }
}
</script>

<style>
.page-enter-active {
  transition: opacity 2s, transform 1s;
}

.page-leave-active {
  transition: opacity 1s, transform 1s;
}

.page-enter, .page-leave-to {
  opacity: 0;
  transform: translateX(-30%);
}

.sliding-in {
    animation: slide .5s ease-in-out;
}

@keyframes slide {
    0% {
        transform: scale(80%) translateY(-10%);
        opacity: 0;
    }
    100% {
        transform: scale(100%) translateY(0%);
        opacity: 1;
    }
}
</style>