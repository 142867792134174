import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import HomeView from '../views/Home.vue'
import LoginView from '@/views/Login.vue'
import OrderView from '@/views/Order.vue'
import CreateOrder from '@/views/CreateOrder.vue'
import ProfileView from '@/views/Profile.vue' 
import SalesView from '@/views/Sales.vue'
import DistributionOrderView from '@/views/DistributionOrder.vue'
import NotFoundView from '@/views/404.vue'
import SalesOrderDetail from '@/views/SalesOrderDetail.vue'
import DistributionOrderDetail from '@/views/DistributionOrderDetail.vue'

const routes = [
  { path: '/', name: 'dashboard', component: HomeView, meta: {title: 'Dashboard', login: true} },
  { path: '/order', name: 'distribution order', component: OrderView, meta: {title: 'Distribution Order', login: true}},
  { path: '/create-do', name: 'create distribution order', component: DistributionOrderView, meta: {title: 'Create DO', login: true}},
  { path: '/do-details/:id', name: 'distribution order detail', component: DistributionOrderDetail, meta: {title: 'DO Detail', login: true}},
  { path: '/create-sales-order', name: 'create sales order', component: CreateOrder, meta: {title: 'Create SO', login: true}},
  { path: '/sales-order-detail/:id', name: 'sales order detail', component: SalesOrderDetail, meta: {title: 'Sales Order', login: true}},
  { path: '/profile', name: 'profile', component: ProfileView, meta: {title: 'Profile', login: true} },
  { path: '/sales', name: 'sales order', component: SalesView, meta: {title: 'Sales Order', login: true} },
  { path: '/login', name: 'login', component: LoginView, meta: {title: 'Login', guest: true}},
  { path: '/404', name: 'not found', component: NotFoundView, meta: {title: 'Page Not Found'} },
  { path: '/:pathMatch(.*)*', name: 'error', component: NotFoundView, meta: {title: 'Page Not Found'} },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  window.document.title = to.meta && to.meta.title ? to.meta.title : 'Home';

  const authStore = useAuthStore()
  if(to.matched.some(record => record.meta.login)){
    if (!authStore.getAuthToken){
      next({
        name: 'login'
      })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.guest)){
    if (authStore.getAuthToken) {
      next({
        name: 'home'
      })
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router
