<template>
    <div class="w-full h-full bg-stone-100 pt-5">
       <div class="px-5">
           <div class="mb-2">
               <a @click="this.$router.go(-1)" class="focus:outline-none hover:underline text-gray-500 text-sm cursor-pointer"><i class="mdi mdi-arrow-left text-gray-400"></i>Back</a>
           </div>
           <div class="mb-2">
               <h1 v-if="!loading" class="text-3xl md:text-5xl font-bold text-gray-600">{{ upperCaseFirst(this.$route.name) }}.</h1>

               <div v-if="loading" class="mt-4 mb-1">
                    <Skeleton width="420px" height="40px"></Skeleton>
                </div>
           </div>
           <div class="mb-5 text-gray-400">
                <div v-if="!loading">
                    <router-link :to="{name: 'dashboard'}" class="focus:outline-none hover:underline text-gray-500">Home</router-link> / <router-link :to="{name: 'sales order'}" class="focus:outline-none hover:underline text-gray-500">Sales Order</router-link>
                </div>

                <div v-if="loading" class="mt-4 mb-1">
                    <Skeleton width="130px" height="15px"></Skeleton>
                </div>
           </div>
       </div>
       
       <div class="relative w-full bg-white border-t border-gray-200 px-5 py-10 text-gray-800">
           <button v-if="!loading" @click="this.$router.push({name: 'create sales order'})" class="text-white py-2 px-4 uppercase rounded bg-teal-600 hover:bg-teal-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5">
                Create SO
            </button>

            <div v-if="loading" class="relative z-10">
                <Skeleton width="112px" height="40px"></Skeleton>
            </div>

            <InputGroup v-if="!loading" class="absolute right-10 top-10" style="width: 500px">
                <Button style="background: rgb(41 37 36);border: none" label="Search" />
                <InputText v-model="searchValue" @input="searching" class="focus:outline-0 active:outline-0 active:outline-stone-900 focus:outline-stone-900" placeholder="Keyword" />
            </InputGroup>

            <div class="w-full mt-4">
                <div class="rounded-md border border-gray-200 bg-white p-6">
                    <h2 v-if="!loading" class="text-stone-700 text-xl font-bold">Apply filters</h2>

                    <div v-if="loading" class="mt-[5px] mb-[5px]">
                        <Skeleton width="140px" height="20px"></Skeleton>
                    </div>

                    <p v-if="!loading" class="mt-1 text-sm">Use filters to further refine search</p>

                    <div v-if="loading" class="mt-[15px] mb-[35px]">
                        <Skeleton width="210px" height="10px"></Skeleton>
                    </div>

                    <div class="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                    <div class="relative flex flex-col">
                        <label v-if="!loading" for="name" class="text-stone-600 text-sm font-medium">Customer</label>

                        <div v-if="loading" class="mt-[5px] mb-[5px]">
                            <Skeleton width="60px" height="10px"></Skeleton>
                        </div>

                        <Select v-if="!loading" v-model="cust" :options="customers" filter optionLabel="cust_name" placeholder="Select a Customer" class="w-full h-[45px] mt-2">
                            <template #value="data">
                                <div v-if="data.value" class="flex items-center">
                                    <i class="large material-icons">account_circle</i>
                                    <div>{{ data.value.cust_name }}</div>
                                </div>
                                <span v-else>
                                    {{ data.placeholder }}
                                </span>
                            </template>
                            <template #option="data">
                                <div class="flex items-center">
                                    <i class="large material-icons">account_circle</i>
                                    <div>{{ data.option.cust_name }}</div>
                                </div>
                            </template>
                        </Select>

                        <Skeleton v-if="loading" class="mt-2" width="100%" height="45px"></Skeleton>
                    </div>

                    <div class="relative flex flex-col">
                        <label v-if="!loading" for="manufacturer" class="text-stone-600 text-sm font-medium">Contract</label>

                        <div v-if="loading" class="mt-[5px] mb-[5px]">
                            <Skeleton width="60px" height="10px"></Skeleton>
                        </div>
                        
                        <Select v-if="!loading" v-model="contract" :options="contracts" filter optionLabel="no_contract" placeholder="Select a Contract" class="w-full h-[45px] mt-2">
                            <template #value="data">
                                <div v-if="data.value" class="flex items-center">
                                    <i class="large material-icons">assignment</i>
                                    <div>{{ data.value.no_contract }}</div>
                                </div>
                                <span v-else>
                                    {{ data.placeholder }}
                                </span>
                            </template>
                            <template #option="data">
                                <div class="flex items-center">
                                    <i class="large material-icons">assignment</i>
                                    <div>{{ data.option.no_contract }}</div>
                                </div>
                            </template>
                        </Select>

                        <Skeleton v-if="loading" class="mt-2" width="100%" height="45px"></Skeleton>
                    </div>

                    <div class="relative flex flex-col">
                        <label v-if="!loading" for="status" class="text-stone-600 text-sm font-medium">Status</label>

                        <div v-if="loading" class="mt-[5px] mb-[5px]">
                            <Skeleton width="60px" height="10px"></Skeleton>
                        </div>

                        <select v-if="!loading" v-model="status" id="status" class="mt-2 block w-full h-[45px] rounded-md border border-gray-200 px-2 py-2 shadow-sm outline-none focus:border-emerald-400">
                            <option value="ALL">All</option>
                            <option value="PENDING">Pending</option>
                            <option value="APPROVED">Approved</option>
                        </select>

                        <Skeleton v-if="loading" class="mt-2" width="100%" height="45px"></Skeleton>
                    </div>
                    </div>

                    <div class="relative mt-6 grid w-full grid-cols-2 justify-end space-x-4 md:flex">
                        <button v-if="!loading" @click="resetFilter" type="button" class="text-gray-600 py-2 px-4 rounded bg-gray-200 hover:bg-gray-300 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5">
                            Reset
                        </button>

                        <div v-if="loading" class="relative z-10">
                            <Skeleton width="72px" height="41px"></Skeleton>
                        </div>
                        
                        <button v-if="!loading" @click="fetchSalesOrder" class="text-white py-2 px-4 rounded bg-teal-600 hover:bg-teal-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5">
                            Filter
                        </button>

                        <div v-if="loading" class="relative z-10">
                            <Skeleton width="69px" height="41px"></Skeleton>
                        </div>
                    </div>
                </div>
            </div>


            <div v-if="loading" class="absolute right-[40px] top-10">
                <Skeleton width="500px" height="42px"></Skeleton>
            </div>

            <div v-if="loading" class="relative mt-11">
                <Skeleton width="100%" height="70px"></Skeleton>
            </div>

            <div v-if="loading" class="relative mt-5">
                <Skeleton width="100%" height="70px"></Skeleton>
            </div>
         
           <div v-if="!loading" class="w-full mt-8">
               <DataTable class="bg-transparent" :value="salesorder" removableSort sortMode="multiple" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50, 100]" tableStyle="min-width: 100%">
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" field="cust_name" header="Customer" sortable style="width: 35%">
                        <template #body="data">
                            <div class="flex gap-3 jsutify-start items-center">
                                <i class="large material-icons">account_circle</i>
                                <span>{{ data.data.cust_name }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 hover:bg-gray-200 dark:bg-stone-900 dark:hover:bg-stone-600 focus:bg-sky-200 dark:focus:bg-sky-200 rounded-tl-lg" field="no_so" header="NO SO" sortable="" style="width: 10%"></Column>
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 hover:bg-gray-200 dark:bg-stone-900 dark:hover:bg-stone-600 focus:bg-sky-200 dark:focus:bg-sky-200" field="so_date" header="SO Date" sortable style="width: 10%"></Column>
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" field="exp_date" header="Expired Date" style="width: 10%"></Column>
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" field="no_contract" header="Contract" style="width: 10%"></Column>
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" field="no_po" header="PO Number" style="width: 10%"></Column>
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 hover:bg-gray-200 dark:bg-stone-900 dark:hover:bg-stone-600 focus:bg-sky-200 dark:focus:bg-sky-200 text-center" header="Status" style="width: 10%">
                       <template #body="data">
                            <div class="w-full flex justify-center">
                                <span v-if="data.data.f_status == 'APPROVED'" class="text-md py-1 px-3 bg-emerald-400 text-white rounded-full flex items-center gap-1">
                                    <i class="material-icons text-sm">check</i>
                                    <span>Approved</span>
                                </span>
                                <span v-if="data.data.f_status == ''" class="text-md py-1 px-3 bg-yellow-400 text-white rounded-full flex items-center gap-1">
                                    <i class="material-icons text-sm">access_time</i>
                                    <span>Pending</span>
                                </span>
                            </div>
                       </template>
                    </Column>
                    <Column class="bg-gray-100 text-gray-800 text-sm" headerClass="h-[80px] bg-gray-300 dark:bg-stone-900" header="Details" style="width: 5%">
                        <template #body="data">
                            <button @click="this.$router.push(`/sales-order-detail/${data.data.no_so}`)" class="text-md py-1 px-1 bg-gray-600 hover:bg-gray-900 text-white rounded-sm flex items-center">
                                <i class="material-icons">view_list</i>
                            </button>
                        </template>
                    </Column>
               </DataTable>
           </div>
       </div>

       <ToastVue position="bottom-right" group="so" />
   </div>
</template>

<script>
import Select from 'primevue/select';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputGroup from 'primevue/inputgroup';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import { useAuthStore } from '@/store/auth';
import { useToast } from 'primevue/usetoast';
import axios from 'axios';

export default {
   name: 'SalesView',
   components: {
       DataTable,
       Column,
       InputGroup,
       Button,
       InputText,
       Skeleton,
       Select,
   },
   data(){
        return {
            loading: true,
            salesorder: [],
            sorder: [],
            customers: [],
            contracts: [],
            cust: null,
            custkd: "",
            contract: null,
            contractno: "",
            status: 'ALL',
            token: null,
            toast: null,
            searchValue: null,
        }
   },
   watch: {
        cust(){
            if(this.cust !== null){
                this.custkd = this.cust.cust_kd
                this.fetchContracts()
            }
        },
        contract(){
            if(this.contract !== null){
                this.contractno = this.contract.contract_no
            }
        }
   },
   created(){
        this.toast = useToast();
        const authStore = useAuthStore()
        this.token = authStore.getAuthToken;

        this.fetchCustomers()
        this.fetchSalesOrder()
   },
   methods: {
        searching(){
            this.items = [];
            const searchTerm = "*" + this.searchValue + "*";
            const wildcardRegex = new RegExp(
                "^" + searchTerm.replace(/\*/g, ".*") + "$",
                "i"
            );

            const matchingObjects = this.sorder.filter((obj) =>
                Object.values(obj).some(
                    (value) => typeof value === "string" && wildcardRegex.test(value)
                )
            );

            this.salesorder = matchingObjects;
        },
        async fetchCustomers() {
            try {
                const {data} = await axios.get(`/get-cust/${this.token}`)
                data.forEach((data, index) => {
                    data.image = `https://i.pravatar.cc/50?img=${index}.jpg`
                });

                this.customers = data
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'so', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout()
            }
        },
        async fetchContracts() {
            try {
                const {data} = await axios.get(`/get-cont-by-cust/${this.custkd}/${this.token}`)

                this.contracts = data
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'so', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout()
            }
        },
        async fetchSalesOrder(){
            try {
                const filter = {
                    customer: this.custkd,
                    contract: this.contractno,
                    status: this.status
                }

                const {data} = await axios.post(`/sales-order-data/${this.token}`, filter)
                
                this.sorder = data;
                this.salesorder = data

                this.loading = false;
            } catch(error){
                this.toast.add({ severity: 'error', summary: 'Server Error', detail: error, group: 'so', life: 3000 });

                if(error.response && error.response.status == 401) this.userLogout()
            }
        },
        resetFilter(){
            this.cust = null
            this.custkd = ""
            this.contractno = ""
            this.status = "ALL"
            this.contracts = []
            this.customers = []
            this.fetchSalesOrder()
        }
   }
}
</script>

<style>

</style>