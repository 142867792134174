<template>
  <layout-vue></layout-vue>
</template>

<script>
import LayoutVue from './components/Layout.vue'
export default{
  name: 'App',
  components: {
    LayoutVue
  }
}
</script>