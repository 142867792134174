<template>
     <div class="w-full h-full bg-stone-100 pt-5">
        <div class="px-5">
            <div class="mb-2">
                <a @click="this.$router.go(-1)" class="focus:outline-none hover:underline text-gray-500 text-sm"><i class="mdi mdi-arrow-left text-gray-400"></i>Back</a>
            </div>
            <div class="mb-2">
                <h1 v-if="!loading" class="text-3xl md:text-5xl font-bold text-gray-600">{{ upperCaseFirst(this.$route.name) }}.</h1>
                
                <div v-if="loading" class="mt-4 mb-1">
                    <Skeleton width="420px" height="40px"></Skeleton>
                </div>
            </div>
            <div class="mb-5 text-gray-400">
                <div v-if="!loading">
                    <a href="#" class="focus:outline-none hover:underline text-gray-500">Home</a> / <a href="#" class="focus:outline-none hover:underline text-gray-500">Profile</a>
                </div>

                <div v-if="loading" class="mt-4 mb-1">
                    <Skeleton width="180px" height="15px"></Skeleton>
                </div>
            </div>
        </div>
        
        <div class="w-full bg-white border-t border-gray-200 px-5 py-10 text-gray-800">
            <div class="relative w-full h-full flex flex-row gap-3 items-start justify-start mb-4 bg-white">
                <div class="mx-0 max-w-screen h-auto">
                    <div class="grid grid-cols-8 sm:grid-cols-12">
                        <div class="col-span-12 h-full overflow-hidden rounded-xl sm:px-8 mb-[50px]">
                            <div class="w-[100%] p-0">
                                <div class="absolute z-1 top-0 left-0 w-full">
                                    <img v-show="!loading" src="../assets/images/bg-sky.jpg" class="w-full h-[200px] object-cover rounded-xl" alt="bg-profile">
                                
                                    <div v-if="loading" class="w-full z-10">
                                        <Skeleton width="100%" height="200px"></Skeleton>
                                    </div>
                                </div>
                                

                                <div v-if="!loading" class="absolute top-[5px] right-[5px] z-2 rounded-full w-[32px] h-[32px] bg-sky-600 hover:bg-cyan-800 cursor-pointer flex items-center justify-center text-white">
                                    <input type="file" accept="image/png,image/jpg,image/jpeg" class="absolute cursor-pointer w-full h-full opacity-0 z-10">
                                    <i class="large material-icons">mode_edit</i>
                                </div>

                                <div class="p-4 bg-white mt-52">
                                    <div class="grid grid-cols-1 md:grid-cols-3">
                                        <div class="grid grid-cols-2 text-center order-last md:order-first mt-20 md:mt-0 z-10">
                                           
                                        </div>
                                        <div class="relative">
                                            <div class="w-48 h-48 bg-indigo-100 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-indigo-500 -mt-[120px]">
                                                <img v-show="!loading" src="../assets/images/default.png" class="rounded-full object-cover" alt="profile">

                                                <Skeleton v-if="loading" width="100%" height="100%" borderRadius="50%"></Skeleton>

                                                <div v-if="!loading" class="absolute bottom-0 right-[30px] z-2 w-8 h-8 rounded-full bg-stone-600 hover:bg-stone-900 text-4xl text-white flex items-center justify-center cursor-pointer overflow-hidden">
                                                    <input type="file" accept="image/png,image/jpg,image/jpeg" class="absolute cursor-pointer w-full h-full opacity-0 z-10">
                                                    <i class="large material-icons">linked_camera</i>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="relative space-x-8 flex justify-between mt-32 md:mt-0 md:justify-center">
                                            <button v-if="!loading" class="text-white py-2 px-4 uppercase rounded bg-emerald-600 hover:bg-blue-500 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5">
                                                Update
                                            </button>

                                            <div v-if="loading">
                                                <Skeleton width="92px" height="52px"></Skeleton>
                                            </div>

                                            <button v-if="!loading" class="text-white py-2 px-4 uppercase rounded bg-cyan-600 hover:bg-gray-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5">
                                                Save Change
                                            </button>

                                            <div v-if="loading">
                                                <Skeleton width="137px" height="52px"></Skeleton>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex flex-col justify-center mt-10 text-center pb-0">
                                        <h1 v-if="!loading" class="text-4xl font-medium text-gray-700">{{ upperCaseFirst(profile.userid) }}, <span class="font-light text-gray-500">47611</span></h1>

                                        <div v-if="loading" class="relative mx-auto mt-4">
                                            <Skeleton width="250px" height="30px"></Skeleton>
                                        </div>

                                        <p v-if="!loading" class="mt-8 text-gray-500 mt-[10px]">{{ upperCaseFirst(profile.department) }} - {{ upperCaseFirst(profile.subdivision) }}</p>

                                        <div v-if="loading" class="relative mx-auto mt-[12px]">
                                            <Skeleton width="280px" height="10px"></Skeleton>
                                        </div>

                                        <p v-if="!loading" class="mt-2 text-gray-500">{{ upperCaseFirst(profile.division) }}</p>

                                        <div v-if="loading" class="relative mx-auto mt-[22px]">
                                            <Skeleton width="135px" height="10px"></Skeleton>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="w-[100svw] mt-4 mb-4"></div>

                            <div class="bg-white mb-6">
                                <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-1">

                                <div class="lg:col-span-2">
                                    <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                                    <div class="md:col-span-6">
                                        <label v-if="!loading" for="full_name">Full Name</label>

                                        <div v-if="loading" class="w-full z-10 mb-2">
                                            <Skeleton width="80px" height="10px"></Skeleton>
                                        </div>

                                        <input v-if="!loading" type="text" name="full_name" id="full_name" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value="" />

                                        <div v-if="loading" class="w-full z-10">
                                            <Skeleton width="100%" height="40px"></Skeleton>
                                        </div>
                                    </div>

                                    <div class="md:col-span-2">
                                        <label v-if="!loading" for="full_name">Username</label>

                                        <div v-if="loading" class="w-full z-10 mt-1 mb-[10px]">
                                            <Skeleton width="80px" height="10px"></Skeleton>
                                        </div>

                                        <input v-if="!loading" type="text" name="username" id="username" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value="" />

                                        <div v-if="loading" class="w-full z-10">
                                            <Skeleton width="100%" height="40px"></Skeleton>
                                        </div>
                                    </div>

                                    <div class="md:col-span-1">
                                        <label v-if="!loading" for="full_name">NIK</label>

                                        <div v-if="loading" class="w-full z-10 mt-1 mb-[10px]">
                                            <Skeleton width="80px" height="10px"></Skeleton>
                                        </div>

                                        <input v-if="!loading" type="text" name="nik" id="nik" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value="" />

                                        <div v-if="loading" class="w-full z-10">
                                            <Skeleton width="100%" height="40px"></Skeleton>
                                        </div>
                                    </div>

                                    <div class="md:col-span-3">
                                        <label v-if="!loading" for="email">Email Address</label>

                                        <div v-if="loading" class="w-full z-10 mt-1 mb-[10px]">
                                            <Skeleton width="80px" height="10px"></Skeleton>
                                        </div>

                                        <input v-if="!loading" type="text" name="email" id="email" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value="" placeholder="email@domain.com" />

                                        <div v-if="loading" class="w-full z-10">
                                            <Skeleton width="100%" height="40px"></Skeleton>
                                        </div>
                                    </div>

                                    <div class="md:col-span-3">
                                        <label v-if="!loading" for="address">Division</label>

                                        <div v-if="loading" class="w-full z-10 mt-1 mb-[10px]">
                                            <Skeleton width="100px" height="10px"></Skeleton>
                                        </div>

                                        <input v-if="!loading" type="text" name="division" id="division" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value="" placeholder="" />

                                        <div v-if="loading" class="w-full z-10">
                                            <Skeleton width="100%" height="40px"></Skeleton>
                                        </div>
                                    </div>

                                    <div class="md:col-span-3">
                                        <label v-if="!loading" for="address">Sub Division</label>

                                        <div v-if="loading" class="w-full z-10 mt-1 mb-[10px]">
                                            <Skeleton width="100px" height="10px"></Skeleton>
                                        </div>

                                        <input v-if="!loading" type="text" name="subdivision" id="subdivision" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value="" placeholder="" />

                                        <div v-if="loading" class="w-full z-10">
                                            <Skeleton width="100%" height="40px"></Skeleton>
                                        </div>
                                    </div>

                                    <div class="md:col-span-3">
                                        <label v-if="!loading" for="address">Department</label>

                                        <div v-if="loading" class="w-full z-10 mt-1 mb-[10px]">
                                            <Skeleton width="100px" height="10px"></Skeleton>
                                        </div>

                                        <input v-if="!loading" type="text" name="department" id="department" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value="" placeholder="" />

                                        <div v-if="loading" class="w-full z-10">
                                            <Skeleton width="100%" height="40px"></Skeleton>
                                        </div>
                                    </div>
                                    
                                    <div class="md:col-span-3">
                                        <label v-if="!loading" for="address">Address</label>

                                        <div v-if="loading" class="w-full z-10 mt-1 mb-[10px]">
                                            <Skeleton width="80px" height="10px"></Skeleton>
                                        </div>
                                        
                                        <input v-if="!loading" type="text" name="department" id="department" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value="" placeholder="" />

                                        <div v-if="loading" class="w-full z-10">
                                            <Skeleton width="100%" height="40px"></Skeleton>
                                        </div>
                                    </div>

                                    <div class="md:col-span-2">
                                        <label v-if="!loading" for="city">City</label>

                                        <div v-if="loading" class="w-full z-10 mt-1 mb-[10px]">
                                            <Skeleton width="50px" height="10px"></Skeleton>
                                        </div>

                                        <input v-if="!loading" type="text" name="city" id="city" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value="" placeholder="" />

                                        <div v-if="loading" class="w-full z-10">
                                            <Skeleton width="100%" height="40px"></Skeleton>
                                        </div>
                                    </div>

                                    <div class="md:col-span-2">
                                        <label v-if="!loading" for="country">Country / region</label>

                                        <div v-if="loading" class="w-full z-10 mt-1 mb-[10px]">
                                            <Skeleton width="110px" height="10px"></Skeleton>
                                        </div>

                                        <div v-if="!loading" class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
                                        <input name="country" id="country" placeholder="Country" class="px-4 appearance-none outline-none text-gray-800 w-full bg-transparent" value="" />
                                        <button tabindex="-1" class="cursor-pointer outline-none focus:outline-none transition-all text-gray-300 hover:text-red-600">
                                            <svg class="w-4 h-4 mx-2 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                            </svg>
                                        </button>
                                        <button tabindex="-1" for="show_more" class="cursor-pointer outline-none focus:outline-none border-l border-gray-200 transition-all text-gray-300 hover:text-blue-600">
                                            <svg class="w-4 h-4 mx-2 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="18 15 12 9 6 15"></polyline></svg>
                                        </button>
                                        </div>

                                        <div v-if="loading" class="w-full z-10">
                                            <Skeleton width="100%" height="40px"></Skeleton>
                                        </div>
                                    </div>

                                    <div class="md:col-span-2">
                                        <label v-if="!loading" for="state">State / province</label>

                                        <div v-if="loading" class="w-full z-10 mt-1 mb-[10px]">
                                            <Skeleton width="110px" height="10px"></Skeleton>
                                        </div>

                                        <div v-if="!loading" class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
                                        <input name="state" id="state" placeholder="State" class="px-4 appearance-none outline-none text-gray-800 w-full bg-transparent" value="" />
                                        <button tabindex="-1" class="cursor-pointer outline-none focus:outline-none transition-all text-gray-300 hover:text-red-600">
                                            <svg class="w-4 h-4 mx-2 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                            </svg>
                                        </button>
                                        <button tabindex="-1" for="show_more" class="cursor-pointer outline-none focus:outline-none border-l border-gray-200 transition-all text-gray-300 hover:text-blue-600">
                                            <svg class="w-4 h-4 mx-2 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="18 15 12 9 6 15"></polyline></svg>
                                        </button>
                                        </div>

                                        <div v-if="loading" class="w-full z-10">
                                            <Skeleton width="100%" height="40px"></Skeleton>
                                        </div>
                                    </div>

                                    <div class="md:col-span-2">
                                        <label v-if="!loading" for="zipcode">Zipcode</label>

                                        <div v-if="loading" class="w-full z-10 mt-1 mb-[10px]">
                                            <Skeleton width="80px" height="10px"></Skeleton>
                                        </div>

                                        <input v-if="!loading" type="text" name="zipcode" id="zipcode" class="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="" value="" />

                                        <div v-if="loading" class="w-full z-10">
                                            <Skeleton width="100%" height="40px"></Skeleton>
                                        </div>
                                    </div>

                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <data class="mb-[20px]"></data>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Skeleton from 'primevue/skeleton';
import { useAuthStore } from '@/store/auth';

export default {
    name: 'ProfileView',
    components: {
        Skeleton,
    },
    data(){
        return {
            loading: true,
            profile: null,
        }
    },
    created(){
        const authStore = useAuthStore()
        this.profile = authStore.getProfile

        setTimeout(() => {
            this.loading = false;
        }, 1500)
    }
}
</script>