<template>
    <div class="absolute left-[40%] top-12 z-10 w-[200px] h-[45px] rounded-md bg-white shadow-md shadow-gray-200 flex items-center justify-center border border-stone-400 fade-in">
        <div class="relative flex flex-row items-center justify-center">
            <img src="../assets/images/icons/exclamation.png" class="w-6 h-6 mr-2 z-50" alt="">
            <span class="text-sm font-400 text-gray-900">Please fill out this field.</span>

            <div class="absolute left-6 -top-[12px] rounded-sm before:absolute before:-bottom-2.5 before:-left-5 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-l-2 before:border-t-2 before:border-white before:bg-white"> </div>
            <div class="absolute left-6 -z-1 -top-[12px] rounded-sm before:absolute before:-bottom-2.5 before:-left-5 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-t before:border-r before:border-stone-400 before:bg-white"> </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RequiredVue'
}
</script>
